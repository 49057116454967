<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10">
      <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>
      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
              <div class="pointer-events-auto relative w-screen max-w-md">
                <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
                  <div class="absolute top-0 left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
                    <button type="button" class="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white" @click="open = false">
                      <span class="sr-only">Zamknij panel</span>
                      <XIcon class="h-6 w-6" @click.prevent="closeModal" aria-hidden="true" />
                    </button>
                  </div>
                </TransitionChild>
                <div class="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                  <div class="px-4 sm:px-6">
                    <DialogTitle class="text-lg font-medium text-gray-900 select-none"> Warianty {{product.name}} </DialogTitle>
                  </div>
                  <div class="relative mt-4 flex-1 px-4 sm:px-6">
                    <div class="bg-white p-px overflow-hidden sm:rounded-md">
                      <div v-for="(variant,variantIndx) in sortedVariantsArray" :key="variantIndx" role="list" class="divide-y divide-gray-100 mb-5 bg-white shadow overflow-hidden sm:rounded-md">
                          <div class="block ">
                          <div class="px-4 py-4 sm:px-6 flex">
                              <div class="flex items-center space-x-4">
                                <div class="flex-shrink-0">
                                    <img v-if="variant.image.url.length > 0 || variant.imagePreview !== undefined" class="h-12 w-12 border rounded-full" :src="variant.image.url.length > 0 ? variant.image.url : variant.imagePreview" alt="" />
                                    <div v-else class="h-12 w-12 rounded-full text-white bg-yellow-400 border flex justify-center items-center text-sm">{{variant.name.substring(0,3).toUpperCase()}}</div>
                                </div>
                                <div class="flex-1 min-w-0">
                                    <p class="text-sm font-medium text-gray-900 truncate">
                                        {{ variant.name }} 
                                    </p>
                                    <p :class="['text-xs truncate', variant.quantity < 1 ? 'text-red-500' : (variant.quantity > 0 && variant.quantity < 4) ? 'text-yellow-500' :  'text-gray-500']">
                                        <span class="text-gray-500 font-medium">Ilość: </span>{{variant.quantity}} szt.
                                        <!-- {{variant.baselinkerId !== undefined ? variant.baselinkerId : ''}} -->
                                    </p>
                                    <p @click.prevent="copyToClipboard(variant.id)" class="transition ease-in-out duration-300 text-xs text-blue-300 hover:text-blue-500 select-none cursor-pointer">
                                        Kopiuj ID wariantu
                                    </p>
                                </div>
                            </div>
                          </div>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { Dialog,  DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XIcon } from '@heroicons/vue/outline'
export default {
    name: "ActualAttribsValuesInProduct",
    props: ['product'],
    components: {Dialog, DialogTitle, TransitionChild, TransitionRoot, XIcon},
    data() {
      return {
          open: true,
          loading: false,
      }
    },
    computed:
    {
      sortedVariantsArray() {
        const variantsArray = Object.entries(this.product.variants).map(([key, value]) => ({
          ...value, 
          id: key
        }));

        return variantsArray.sort((a, b) => {
          const numberPattern = /\d+$/; // Regex to match numbers at the end of the string
          const numberA = parseInt(a.name.match(numberPattern), 10);
          const numberB = parseInt(b.name.match(numberPattern), 10);
          return numberB - numberA;
        });
      }
    },
    methods:
    {
      copyToClipboard(id)
        {
            navigator.clipboard.writeText(id).then(()=>
            {
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Skopiowano do schowka!",
                    subheader: ``,
                    success: true
                }); 
            })

        },
        closeModal()
        {
            this.$emit("close");
        }
    }
}
</script>
