<template>
<TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto">
        <div class="flex items-center justify-center pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <TransitionChild as="div" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" @click="close()" />
            </TransitionChild>
            <!-- This element is to trick the browser into centering the modal contents. -->
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <div class="relative inline-block align-middle self-center bg-white rounded-lg text-left shadow-xl transform transition-all duration-300 w-full max-w-lg pt-6 px-6">
                    <div class="flex justify-between">
                        <DialogTitle class="text-lg font-medium text-gray-900 select-none">Historia zamówienia</DialogTitle>
                        <div class="flex gap-3 items-center">
                            <!-- Images -->
                            <a v-if="typeof orderDetails.image !== 'object' ? true : false" :class="[orderDetails.image === undefined || orderDetails.image === null ? 'opacity-30 pointer-events-none select-none' : '', 'h-5 w-5 text-gray-400 group-hover:text-gray-500']" :href="orderDetails.image" target="_blank" rel="noopener noreferrer">
                                <PhotographIcon v-tooltip="`Wyświetl zdjęcie zamówienia.`" class="w-5 h-5 text-gray-400 hover:text-gray-500 transition-all duration-300" />
                            </a>
                            <!-- <PhotographIcon v-if="typeof orderDetails.image !== 'object' ? true : false" :class="[orderDetails.image === undefined || orderDetails.image === null ? 'opacity-30 pointer-events-none select-none' : '', 'h-5 w-5 text-gray-400 group-hover:text-gray-500']" aria-hidden="true" /> -->
                            <Menu v-if="typeof orderDetails.image === 'object' ? true : false" as="div" :class="[orderDetails.image === undefined || orderDetails.image === null ? 'opacity-30 pointer-events-none select-none' : '', 'relative inline-block text-left']">
                                <div>
                                    <MenuButton v-tooltip="`Wyświetl zdjęcia zamówienia.`" as="div" class="cursor-pointer -mb-0.5 itext-sm font-semibold text-gray-400 hover:text-gray-500 transition-all duration-300">
                                        <PhotographIcon class="['h-5 w-5 text-gray-400']" aria-hidden="true" />
                                    </MenuButton>
                                </div>

                                <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                                <MenuItems class="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div v-for="(img, index) in orderDetails.image.img.url" :key="index">
                                        <div class="flex justify-between">
                                            <MenuItem as="div" v-slot="{ active }" class="flex items-center w-4/5">
                                                <a :href="img" :target="orderDetails.image !== undefined ? '_blank' : ''" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'group flex items-center px-4 py-2 text-sm w-full']">
                                                    <PhotographIcon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                                    Zdjęcie {{index+1}}
                                                </a>
                                            </MenuItem>
                                            <div class="w-1/5 flex items-center justify-center">
                                                <a :href="`http://www.google.com/maps/place/${orderDetails.image.location.coords.latitude+','+orderDetails.image.location.coords.longitude}`" target="_blank" rel="noopener noreferrer">
                                                    <LocationMarkerIcon v-tooltip="`Wyświetl lokalizację zrobionego zdjęcia`" class="w-5 h-5 text-gray-400 hover:text-gray-500 transition-all duration-300" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </MenuItems>
                                </transition>
                            </Menu>
                            <!-- Signature -->
                            <PencilAltIcon :class="[orderDetails.signature === undefined || orderDetails.signature === null ? 'pointer-events-none select-none opacity-30' : '', 'h-5 w-5 text-gray-400 hover:text-gray-500']" aria-hidden="true" />
                            <SwitchVerticalIcon v-if="history.length > 1" @click="toggleSort" class="w-5 text-gray-400 hover:text-gray-500 transition-all duration-300 cursor-pointer" />
                            <XIcon @click="close" class="w-5 text-gray-400 hover:text-gray-500 transition-all duration-300 cursor-pointer"/>
                        </div>
                    </div>
                    <LoadingSpinnerHub v-if="loading"/>
                    <EmptyState class="mt-4" v-if="!loading && !history.length"/>
                    <div class="z-10 flex-1 pt-4 pb-6 ">
                        <ul class="max-h-96 overflow-y-auto">
                            <li v-for="(item, index) in history" :key="index">
                                <!-- <template v-if="item.meta.createdBy !== 'Complaia Systems'"> -->
                                    <div class="flex border-l-4 mb-2">
                                        <div class="flex flex-col pl-4">
                                            <div class="flex gap-2">
                                                <span>{{item.message}}</span>
                                                <Popper @open:popper="getRouteTitle(item)" placement="right" arrow v-if="item.details && item.details.length > 0">
                                                    <button class="m-0">
                                                        <InformationCircleIcon class="w-5 text-gray-300 hover:text-gray-400 transition-all duration-300 cursor-pointer"/>
                                                    </button>
                                                    <template #content>
                                                        <div class="bg-gray-700 text-white p-2 bg-opacity-75 rounded-lg border max-h-96 overflow-x-auto">
                                                            <ul>
                                                                <li v-for="message, index of item.details" :key="index" class="p-2" :class="index !== item.details.length - 1 ? 'border-b' : ''">
                                                                    <span v-if="!item.loading">{{ message }}</span>
                                                                    <span v-else>Ładowanie...</span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </template>
                                                </Popper>
                                            </div>
                                            <span class="text-xs text-gray-700">{{moment.unix(item.meta.createdDate.seconds).format("DD.MM.YYYY HH:mm")}} przez {{item.meta.createdBy}}</span>
                                        </div>
                                    </div>
                                <!-- </template> -->
                            </li>
                        </ul>
                        <div class="flex items-center justify-center mt-4" v-if="queryLimitReached">
                            <button type="button" @click.prevent="loadMoreHistory" class="transition ease-in-out duration-300 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Załaduj więcej..</button>
                        </div>    
                    </div>
                </div>
            </TransitionChild>
        </div>
    </Dialog>
</TransitionRoot>
</template>

<script>
// import axios from 'axios';
import {db} from "@/firebase/gfbconf.js";
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { XIcon, SwitchVerticalIcon, InformationCircleIcon, PhotographIcon, PencilAltIcon, ChevronDownIcon, LocationMarkerIcon } from '@heroicons/vue/solid'
import moment from 'moment'
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import LoadingSpinnerHub from './LoadingSpinnerHub.vue';
import EmptyState from "./EmptyState.vue"

export default {
  components: {
    Menu, MenuButton, MenuItem, MenuItems, ChevronDownIcon, LocationMarkerIcon, LoadingSpinnerHub, PhotographIcon, PencilAltIcon, EmptyState, DialogOverlay, Dialog, DialogTitle, TransitionChild, TransitionRoot, XIcon, SwitchVerticalIcon, InformationCircleIcon
  },
  emits: ["close"],
  props: ["order"],
  data() {
    return {
        moment: moment,
        open: true,
        loading: false,
        sortType: "desc",
        queryLimitReached: true,
        queryLimit: 10,
        history: [],
        orderDetails: {
            image: null,
            signature: null
        },
        detailsLoading: false,
    }
  },
  methods: {
    loadMoreHistory() {
        this.queryLimit += 10
        this.getOrderHistory()
    },
    toggleSort() {
        if(this.sortType === "desc") {
            this.sortType = "asc"
        } else {
            this.sortType = "desc"
        }
        this.getOrderHistory()
    },
    emitAccept() {
        this.$emit("accept");
        this.$emit("closeModal");
    },
    close() {
        this.$emit("close")
        this.open = false
    },
    async getOrdersDetails()
    {
        try
        {
            this.detailsLoading = true;
            const imageRes = await db.collection("PhotosOfPackages").where("orderId", "==", this.order.id).get();
            if(!imageRes.empty)
            {
                imageRes.forEach(doc => {
                    let current = doc.data();
                    if(typeof current.img.url !== 'string')
                    {
                        this.orderDetails.image = current;
                    }
                    else
                    {
                        this.orderDetails.image = current.img.url;
                    }
                    if(current.location !== undefined)
                    {
                        this.orderDetails.image.location = current.location;
                    }
                });
            }
            else
            {
                this.orderDetails.image = null;
            };
            const signatureRes = await db.collection('Signatures').where("orderId", "==", this.order.id).get()
            if(!signatureRes.empty)
            {
                this.orderDetails.signature = signatureRes.docs[0].data().img;
            }
            else
            {
                this.orderDetails.signature = null;
            };
            setTimeout(() => {
                this.detailsLoading = false;
            }, 10);
        }
        catch (error)
        {
            console.log(error);
        }       
    },
    async getRouteTitle(item) {
        item.loading = true
        if(!item.routeId) {
            item.loading = false
            return
        }
        try {
            const routeSnap = await db.collection("Routes").doc(item.routeId).get()
            let routeTitle = ""
            if(!routeSnap.exists) {
                routeTitle = "TRASA USUNIĘTA"
            } else {
                 routeTitle = routeSnap.data().title
            }
            item.details = [`Nazwa trasy: ${routeTitle || "BRAK"}`]
            item.loading = false
        } catch (error) {
            console.log(error);
            item.loading = false
        }
    },
    async getOrderHistory()
    {
        try {
            this.loading = true
            this.history = []
            this.queryLimitReached = true

            const history = await db.collection("Orders").doc(this.order.id).collection("History").orderBy("meta.createdDate", this.sortType).limit(this.queryLimit).get()
            for(let doc of history.docs) {
                const historyItem = doc.data()
                historyItem.loading = false
                this.history.push(historyItem)
            }
            if(history.docs.length < this.queryLimit) {
                this.queryLimitReached = false
            }
            this.getOrdersDetails()
            this.loading = false
        } catch (error) {
            console.log(error);
        }
    },
    debugBase64(base64URL)
    {
        var win = window.open();
        win.document.write('<iframe src="' + base64URL  + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
    },
  },
  async created() {
    await this.getOrderHistory()
  }
}
</script>

<style scoped>
  .fade-enter-active,
  .fade-leave-active {
  transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
  opacity: 0;
  }
</style>
