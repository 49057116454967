<template>
    <div>
        <div>
            <nav class="hidden md:flex mb-4 h-8" aria-label="Breadcrumb">
                <ol role="list" class="flex space-x-4 rounded-md bg-white px-6 shadow">
                <li class="flex">
                    <div class="flex items-center">
                    <router-link to="/hub" class="transition ease-in-out duration-300 text-gray-400 hover:text-gray-500">
                        <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                        <span class="sr-only">Home</span>
                    </router-link>
                    </div>
                </li>
                <li class="flex">
                    <div class="flex items-center">
                    <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                    </svg>
                    <router-link to="/dashboard/orders" class="transition ease-in-out duration-300 ml-4 text-xs font-medium text-gray-500 hover:text-gray-700" aria-current="page">Menu zamówień</router-link>
                    </div>
                </li>
                <li class="flex">
                    <div class="flex items-center">
                    <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                    </svg>
                    <div class="ml-4 text-xs font-medium text-gray-400 cursor-default" aria-current="page">Lista zamówień</div>
                    </div>
                </li>
                </ol>
            </nav>
            <!-- <Transition name="fade">
            <div v-if="!loading" class="flex items-center gap-1">
                <span class="text-gray-500 hover:text-gray-400 select-none transition-all duration-300 font-semibold text-md">({{this.queryNumber}} <span class="text-sm opacity-80">{{customWordEnding(this.queryNumber)}}</span>)</span>
            </div>
            </Transition> -->
        </div>
        <router-link to="/dashboard/orders" class="block md:hidden transition ease-in-out duration-300 mb-2 text-sm font-medium text-blue-500 hover:text-blue-700" aria-current="page">&larr; Cofnij</router-link>

        <!-- <label for="search" class="block text-sm font-medium text-gray-700 mt-4">Search</label> -->

        <!-- SEARCHER -->
        <div class="min-w-0 flex-1 xl:col-span-6">
                <div :class="['w-full flex flex-col gap-2 lg:gap-0 lg:flex-row lg:justify-between']">
                    <Transition name="fade">
                    <div v-if="!this.showStatusFilters" class="flex flex-col lg:flex-row gap-2">
                        <label for="search" class="sr-only">Szukaj</label>
                        <div class="relative">
                            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                <SearchIcon class="mb-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                            </div>
                            <input :disabled="this.searcher.locked" v-model="searcher.phrase" id="search" name="search" :class="['block w-full rounded-md border border-gray-300 bg-white py-2 pl-10 text-sm placeholder-gray-500 focus:border-blue-500 focus:text-gray-900 focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-blue-500', this.searcher.locked ? 'opacity-50' : '']" placeholder="Wyszukaj" type="search" />
                        </div>
                        <button type="button" :disabled="this.searcher.locked" @click.prevent="search" :class="[this.searcher.locked || this.searcher.phrase.length === 0 ? 'opacity-50 cursor-default' : '', 'transition-all duration-300 rounded-md bg-indigo-100 px-5 py-3 text-xs font-semibold text-indigo-600 shadow-sm hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2']">
                            Szukaj
                        </button>
                    </div>
                    </Transition>
                    <Transition name="fade">
                    <!-- <div v-if="showStatusFilters" class="mr-4 flex flex-col lg:flex-row w-full justify-between lg:items-center lg:space-x-3 space-y-4 lg:space-y-0 select-none"> -->
                    <div v-if="showStatusFilters" class="flex flex-col justify-between gap-4 select-none">
                        <div class="flex flex-col lg:flex-row justify-center mt-2 lg:mt-0 lg:items-center lg:space-x-3 space-y-4 lg:space-y-0">
                            <div class="relative rounded-md focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                                <label for="name" class="absolute -top-2 left-2 -mt-px inline-block bg-gradient-to-t from-white to-gray-100 px-1 text-xs font-medium text-gray-900 rounded-t-md">Status</label>
                                <select v-model="pickedStatus" class="block w-full pl-3 pr-10 py-2 text-sm border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md">
                                    <option value="all">Wszystkie</option>
                                    <!-- 1,2,3,4,5,7,401,400,402 -->
                                    <option v-for="status in statuses.filter(el => el.id !== 1 && el.id !== 2 && el.id !== 3 && el.id !== 4 && el.id !== 5 && el.id !== 7 && el.id !== 401 && el.id !== 400 && el.id !== 402 && el.id !== 403)" :value="status.id" :key="status.id">{{status.name}}</option>
                                </select>
                            </div>
                            <!-- old date picker -->
                            <!-- <div class="flex items-center">
                                <div :class="{'relative w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600': true, 'opacity-50': pickedDateOfDelivery !== null || pickedDateOfDeliveryUnix !== null}">
                                    <label for="name" class="absolute -top-2 left-2 -mt-px inline-block bg-gradient-to-t from-white to-gray-100 px-1 text-xs font-medium text-gray-900">Data utworzenia</label>
                                    <input :disabled="pickedDateOfDelivery !== null || pickedDateOfDeliveryUnix !== null" type="date" @change="pickDateOfCreate($event)" v-model="pickedDateOfCreate" ref="pickedCreatedDate"  name="name" id="name" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 text-sm" />
                                </div>
                                <Transition name="fade2">
                                <div v-if="pickedDateOfCreate !== null || pickedDateOfCreateUnix !== null" class="ml-2 mr-2">
                                    <span @click="resetDateOfCreate" class="transition ease-in-out duration-300 text-sm text-red-500 hover:text-red-700 cursor-pointer">&#10005;</span>
                                </div>
                                </Transition>
                            </div> -->
                            <!-- <div class="flex items-center">
                                <div :class="{'relative w-full rounded-md border border-gray-300 lg:w-44 bg-white px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600': true, 'opacity-50': pickedDateOfCreate !== null || pickedDateOfCreateUnix !== null}">
                                    <label for="name" class="absolute -top-2 left-2 -mt-px inline-block bg-gradient-to-t from-white to-gray-100 px-1 text-xs font-medium text-gray-900">Data planowanej dostawy</label>
                                    <input :disabled="pickedDateOfCreate !== null || pickedDateOfCreateUnix !== null" type="date" @change="pickDateOfDelivery($event)" v-model="pickedDateOfDelivery" ref="suggestedDeliveryDate"  name="name" id="name" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 text-sm" />
                                </div>
                                <Transition name="fade2">
                                <div v-if="pickedDateOfDelivery !== null || pickedDateOfDeliveryUnix !== null" class="ml-2 mr-2">
                                    <span @click="resetDateOfDelivery" class="transition ease-in-out duration-300 text-sm text-red-500 hover:text-red-700 cursor-pointer">&#10005;</span>
                                </div>
                                </Transition>
                            </div> -->
                            <!-- vuedatepicker new -->
                            <div class="flex items-center">
                                <div :class="{'relative w-full rounded-md border border-gray-300 lg:w-44 bg-white shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600 transition-all duration-300': true, 'opacity-50 pointer-events-none': pickedDateOfDeliveryPeriodUnix !== null || pickedDateOfMonthCreateUnix !== null}">
                                    <label for="name" class="absolute z-10 -top-2 left-2 -mt-px inline-block bg-gradient-to-t from-white to-gray-100 px-1 text-xs font-medium text-gray-900">
                                        Data utworzenia
                                    </label>
                                    <VueDatePicker placeholder="dd/mm/yyyy - dd/mm/yyyy" v-model="date.create" :enable-time-picker="false" range hide-input-icon auto-apply :month-picker="false" :clearable="false" locale="pl" />
                                </div>
                                <Transition name="fade2">
                                <div v-if="pickedDateOfCreatePeriodUnix !== null" class="ml-2 mr-2">
                                    <span @click="resetDateOfCreate" class="transition ease-in-out duration-300 text-sm text-red-500 hover:text-red-700 cursor-pointer">&#10005;</span>
                                </div>
                                </Transition>
                            </div>
                            <div class="flex items-center">
                                <div :class="{'relative w-full rounded-md border border-gray-300 lg:w-44 bg-white shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600 transition-all duration-300': true, 'opacity-50 pointer-events-none': pickedDateOfCreatePeriodUnix !== null || pickedDateOfMonthCreateUnix !== null}">
                                    <label for="name" class="absolute z-10 -top-2 left-2 -mt-px inline-block bg-gradient-to-t from-white to-gray-100 px-1 text-xs font-medium text-gray-900">
                                        Data planowanej dostawy
                                    </label>
                                    <VueDatePicker placeholder="dd/mm/yyyy - dd/mm/yyyy" v-model="date.delivery" :enable-time-picker="false" range hide-input-icon auto-apply :month-picker="false" :clearable="false" locale="pl" />
                                </div>
                                <Transition name="fade2">
                                <div v-if="pickedDateOfDeliveryPeriodUnix !== null" class="ml-2 mr-2">
                                    <span @click="resetDateOfDelivery" class="transition ease-in-out duration-300 text-sm text-red-500 hover:text-red-700 cursor-pointer">&#10005;</span>
                                </div>
                                </Transition>
                            </div>
                            <div class="flex items-center">
                                <div :class="{'relative w-full rounded-md border border-gray-300 lg:w-44 bg-white shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600 transition-all duration-300': true, 'opacity-50 pointer-events-none': pickedDateOfCreatePeriodUnix !== null || pickedDateOfDeliveryPeriodUnix !== null}">
                                    <label for="name" class="absolute z-10 -top-2 left-2 -mt-px inline-block bg-gradient-to-t from-white to-gray-100 px-1 text-xs font-medium text-gray-900">
                                        Miesiąc utworzenia
                                    </label>
                                    <VueDatePicker placeholder="mm/yyyy" v-model="date.month" hide-input-icon auto-apply :month-picker="true" :clearable="false" locale="pl" />
                                </div>
                                <Transition name="fade2">
                                <div v-if="pickedDateOfMonthCreateUnix !== null" class="ml-2 mr-2">
                                    <span @click="resetDateOfCreate" class="transition ease-in-out duration-300 text-sm text-red-500 hover:text-red-700 cursor-pointer">&#10005;</span>
                                </div>
                                </Transition>
                            </div>
                            
                            <div class="relative rounded-md focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                                <label for="name" class="absolute -top-2 left-2 -mt-px inline-block bg-gradient-to-t from-white to-gray-100 px-1 text-xs font-medium text-gray-900 rounded-t-md">Kraj</label>
                                <select @change="changeSort()" v-model="countryName" class="block w-full pl-3 pr-10 py-2 text-sm border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md">
                                    <option value="all">Wszystkie</option>
                                    <option v-for="(country, index) in countries" :key="index" :value="country.name">{{country.displayName}}</option>
                                </select>
                            </div>
                            <div class="relative rounded-md focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                                <label for="name" class="absolute -top-2 left-2 -mt-px inline-block bg-gradient-to-t from-white to-gray-100 px-1 text-xs font-medium text-gray-900 rounded-t-md">Producent</label>
                                <select @change="changeSort()" v-model="manufacturerName" class="block w-full pl-3 pr-10 py-2 text-sm border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md">
                                    <option value="all">Wszyscy</option>
                                    <option v-for="(manu, index) in manufacturers" :key="index" :value="manu.name">{{manu.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="flex flex-col lg:flex-row lg:space-x-3 space-y-4 lg:space-y-0">
                            <div class="relative rounded-md focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                                <label for="name" class="absolute -top-2 left-2 -mt-px inline-block bg-gradient-to-t from-white to-gray-100 px-1 text-xs font-medium text-gray-900 rounded-t-md">Sortowanie</label>
                                <select @change="changeSort()" v-model="sortName" class="block w-full pl-3 pr-10 py-2 text-sm border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md">
                                    <option v-if="!hideOptions" value="creationAsc">Utworzono: Od najstarszych</option>
                                    <option v-if="!hideOptions" value="creationDesc">Utworzono: Od najnowszych</option>
                                    <option value="suggestedDeliveryAsc">P. dostawa: Od najwcześniejszych</option>
                                    <option value="suggestedDeliveryDesc">P. dostawa: Od najpóźniejszych</option>
                                    <!-- <option v-for="status in statuses" :value="status.id" :key="status.id">{{status.name}}</option> -->
                                </select>
                            </div>
                            <button type="button" @click="this.pickStatus()" class="transition ease-in-out duration-300 inline-flex items-center justify-center rounded border border-transparent bg-blue-100 px-2.5 py-2 lg:py-1.5 text-xs font-medium text-blue-700 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">Zastosuj</button>
                        </div>
                    </div>
                    </Transition>
                    <div v-if="searcher.found.length === 0" :class="{'flex justify-between items-end lg:items-center lg:justify-start transition-all duration-1000 w-auto': true, 'min-w-filters': showStatusFilters}">
                        <div @click="showFilters()" class="mr-4 cursor-pointer flex justify-center items-center flex-col select-none">
                            <FilterIcon class="h-4 w-4 text-blue-500 flex-shrink-0" aria-hidden="true" />
                            <span class="text-xs font-medium text-blue-700">{{showStatusFilters ? 'Resetuj filtry' : 'Filtry'}}</span>
                        </div>
                        <router-link v-if="!showStatusFilters" to="/dashboard/orders/list">
                            <button type="button" :class="['transition-all duration-300 inline-flex items-center gap-x-1.5 rounded-md bg-indigo-200 px-3 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600']">
                                <ArrowLeftIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                                Powrót
                            </button>
                        </router-link>
                    </div>
                </div>
               
            <div class="mt-4" v-if="searcher.found.length > 0">
                <span class="inline-flex items-center py-0.5 pl-2 pr-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-700">
                    Wynik wyszukiwania dla frazy: "{{searcher.lastCall.phrase}}" (Znaleziono: {{searcher.found.length}})
                    <button @click.prevent="resetSearch"  type="button" class="transition ease-in-out duration-300 flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-blue-400 hover:bg-blue-200 hover:text-blue-500 focus:outline-none focus:bg-blue-500 focus:text-white">
                        <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                            <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
                        </svg>
                    </button>
                </span>          
            </div>
           
        </div>
   
        <!-- <Spinner v-if="loading"></Spinner> -->
        <div class="mt-4 flex flex-col pl-2 pr-4 sm:pl-0 sm:pr-6 lg:px-0">
            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle sm:px-6 pl-2 pr-0.5 lg:px-8">
                    <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg" v-if="!loading">
                        <table class="min-w-full divide-y divide-gray-300">
                            <thead class="bg-gray-50">
                                <tr>
                                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Kraj</th>
                                <th scope="col" class="pr-3 py-3.5 text-left text-sm font-semibold text-gray-900">Kod pocztowy</th>
                                <th scope="col" class="pr-3 py-3.5 text-left text-sm font-semibold text-gray-900">Nazwa mebla</th>
                                <th scope="col" class="pr-3 py-3.5 text-left text-sm font-semibold text-gray-900">Producent</th>
                                <th scope="col" class="pr-3 py-3.5 text-left text-sm font-semibold text-gray-900">Status</th>
                                <th scope="col" class="pr-3 py-3.5 text-left text-sm font-semibold text-gray-900">Planowana dostawa</th>
                                <th scope="col" class="pr-3 py-3.5 text-left text-sm font-semibold text-gray-900"><span class="sr-only">Data</span></th>
                                <th scope="col" class="pr-3 py-3.5 text-left text-sm font-semibold text-gray-900"><span class="sr-only">Generowanie faktury</span></th>
                                <th scope="col" class="pr-0 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    <div v-tooltip="`Wygeneruj załadowane zamówienia do pliku CSV.`" @click="downloadAllOrdersAsCSV()" class="h-full cursor-pointer flex justify-center items-center">
                                        <CollectionIcon class="transition ease-in-out duration-300 h-5 w-5 text-gray-400 hover:text-gray-600 flex-shrink-0" aria-hidden="true" />
                                    </div>
                                </th>
                                <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                    <span class="sr-only">Edycja</span>
                                </th>
                                </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-200 bg-white">
                                <tr v-for="order in searcher.found.length > 0 ? searcher.found : orders" :key="order.id">
                                    <td class="whitespace-nowrap py-4 pr-3 pl-4 text-xs sm:pl-6">
                                        <div class="flex items-center">
                                            <img v-if="order.countryFlagURL.length>0" :src="order.countryFlagURL" v-tooltip="`${order.delivery.country.code}`" class="h-4 w-6 border border-gray-300">
                                            <span v-if="order.countryFlagURL.length === 0 && order.delivery.countryCode.length === 0">--</span>
                                        </div>
                                    </td>
                                    <td class="whitespace-nowrap py-4 pr-3 pl-4 text-xs sm:pl-6">
                                        <div class="flex items-center">
                                            <span v-if="order.delivery.postCode.length>0">{{ order.delivery.postCode }}</span>
                                            <span v-if="order.delivery.postCode.length === 0 && order.delivery.postCode.length === 0">--</span>
                                        </div>
                                    </td>
                                    <td class="whitespace-nowrap py-4 pr-3 text-xs">
                                        <div class="flex items-center">
                                            <span @click.prevent="openCommentsModal(order)" v-tooltip="`Zobacz komentarze wewnętrzne`" :class="[order.statusId === 400 && createCommentList(order)[0].length > 0 ? 'bg-red-100 text-red-800' : 'bg-blue-100 text-blue-800', 'mr-1 inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium cursor-pointer']" v-for="prod in createProductList(order).slice(0,1)" :key="prod">{{prod}}</span>
                                            <span @click.prevent="openCommentsModal(order)" class="cursor-pointer" v-tooltip="`${createProductList(order).slice(1).toString().replaceAll(',',' | ')}`" v-if="createProductList(order).length - 1 > 0">+ {{createProductList(order).length - 1}} więcej</span>
                                        </div>
                                    </td>
                                    <td class="whitespace-nowrap py-4 pr-3 text-xs">
                                        <span class="mr-1 inline-flex items-center rounded-full py-0.5 text-xs font-medium" v-for="manu in getManufacturer(order).slice(0,1)" :key="manu">{{typeof manu === 'object' ? manu.name : manu}}</span>
                                        <span v-tooltip="`${getManufacturer(order).slice(1).toString().replaceAll(',',' | ')}`" v-if="getManufacturer(order).length - 1 > 0">+ {{getManufacturer(order).length - 1}} więcej</span>
                                    </td>
                                    <td class="whitespace-nowrap py-4 pr-3 text-xs">
                                        <span v-tooltip="`Status: ${getStatusById(order.statusId).name} - Informacje: ${getStatusById(order.statusId).info}`" :class="{'cursor-pointer inline-flex w-full justify-center items-center rounded-md px-2 py-1 text-xs font-medium': true, 'bg-yellow-400 text-white': order.statusId === 1, 'bg-yellow-600 text-yellow-100': order.statusId === 2, 'bg-blue-500 text-blue-100': order.statusId === 3, 'bg-purple-400 text-purple-100': order.statusId === 4, 'bg-pink-500 text-pink-100': order.statusId === 5, 'bg-green-500 text-green-100': order.statusId === 6, 'bg-purple-500 text-purple-100': order.statusId === 7, 'bg-pink-800 text-pink-100': order.statusId === 400, 'bg-orange-400 text-orange-100': order.statusId === 401, 'bg-teal-500 text-teal-100': order.statusId === 402, 'bg-gray-400 text-gray-100': order.statusId === 403, 'bg-gray-500 text-gray-100': order.statusId === 404, 'bg-red-600 text-red-100': order.statusId === 300, 'bg-gray-300 text-gray-100': order.statusId === 8}">
                                            {{order.statusId === 2 ? 'Kompletowanie' : getStatusById(order.statusId).name}}
                                        </span>
                                    </td>
                                    <td class="whitespace-nowrap py-4 pr-3 text-xs">
                                        <span v-if="order.delivery.date.suggested !== null">{{order.delivery.date.suggested.asText}}</span>
                                        <span v-if="order.delivery.date.suggested === null" class="opacity-60">Nie wskazano</span>
                                    </td>
                                    <td>
                                        <div v-tooltip="`Data wpłynięcia zamówienia: ${order.origin !== undefined ? moment.unix(order.origin.meta.external.dates.add_TimestampUNIX).format('LLL') : moment.unix(order.meta.created.unix).format('LLL')}`" class="h-full cursor-pointer flex justify-center items-center">
                                            <AnnotationIcon class="transition ease-in-out duration-300 h-5 w-5 text-gray-400 hover:text-gray-600 flex-shrink-0" aria-hidden="true" />
                                        </div>
                                    </td>
                                    <td>
                                        <div v-tooltip="`Wyświetl historię`" @click="openHistoryModal(order)" class="h-full cursor-pointer flex justify-center items-center">
                                            <NewspaperIcon class="transition ease-in-out duration-300 h-5 w-5 text-gray-400 hover:text-gray-600 flex-shrink-0" aria-hidden="true" />
                                        </div>
                                    </td>
                                    <td class="pl-1">
                                        <div class="flex items-center">
                                        <Menu as="div" class="relative inline-block text-left">
                                            <div>
                                                <MenuButton class="transition-all duration-300 flex items-center rounded-full text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                                                    <span class="sr-only">Open options</span>
                                                    <DotsVerticalIcon class="h-5 w-5" aria-hidden="true" />
                                                </MenuButton>
                                            </div>
                                            <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                                                <MenuItems class="absolute right-0 z-10 mt-2 w-48 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                    <div class="py-1">
                                                        <MenuItem v-slot="{ active }" v-tooltip="`Drukuj`">
                                                            <a @click.prevent="printLabel(order)" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'cursor-pointer group flex items-center px-4 py-2 text-sm transition-all duration-300']">
                                                                <PrinterIcon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                                                Drukuj
                                                            </a>
                                                        </MenuItem>
                                                        <MenuItem v-slot="{ active }" v-tooltip="`${order.meta.generated ? 'Faktura już została wygenerowana. Naciśnij, aby pobrać jedną fakturę.' : 'Faktura nie została jeszcze wygenerowana. Naciśnij, aby pobrać jedną fakturę.'}`">
                                                            <a @click.prevent="downloadAllOrdersAsCSV(order)" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'cursor-pointer group flex items-center px-4 py-2 text-sm transition-all duration-300']">
                                                                <ClipboardIcon v-if="!order.meta.generated" class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                                                <ClipboardCheckIcon v-if="order.meta.generated" class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                                                Generuj
                                                            </a>
                                                        </MenuItem>
                                                    </div>
                                                </MenuItems>
                                            </transition>
                                        </Menu>
                                        </div>
                                    </td>
                                    <td class="relative flex justify-end whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                        <router-link :to="`/dashboard/orders/add?edit=${order.id}`" target="_blank" class="transition ease-in-out duration-300 text-blue-600 hover:text-blue-900">
                                            <div class="transition ease-in-out duration-300 relative inline-flex items-center px-4 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500">Edytuj</div>
                                            <span class="sr-only">, {{ order.id }}</span>
                                        </router-link>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                       
                    </div>
                </div>
            </div>
        </div>
        <EmptyState class="mt-2 mb-2" v-if="orders.length === 0"></EmptyState>
        <div class="flex items-center justify-center mt-4" v-if="queryLimitReached === false">
            <button type="button" @click.prevent="loadMoreOrders" class="transition ease-in-out duration-300 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Załaduj więcej..</button>
        </div>
        <CommentsModal v-if="commentsModal.toggle" :order="commentsModal.data" @close="closeCommentsModal"></CommentsModal>
        <HistoryModal v-if="historyModal.toggle" :order="historyModal.data" @close="closeHistoryModal"></HistoryModal>  
    </div>
</template>

<script>
// import Spinner  from '@/components/loadingSpinner.vue';
import {db} from "@/firebase/gfbconf.js";
import axios from 'axios';
import moment from 'moment';
import currency from 'currency.js';
import EmptyState from '../components/EmptyState.vue';
import { CollectionIcon, ClipboardIcon, ClipboardCheckIcon, AnnotationIcon, SearchIcon, PlusSmIcon, XIcon, FilterIcon, PrinterIcon, ArrowLeftIcon, NewspaperIcon, DotsVerticalIcon, PencilAltIcon, PhotographIcon } from '@heroicons/vue/outline';
import { HomeIcon } from '@heroicons/vue/solid';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import CommentsModal from "@/components/CommentsInOrder.vue";
import HistoryModal from "../components/ModalOrderHistory.vue"
import { MenuButton, MenuItem, MenuItems, Menu } from "@headlessui/vue"
import LoadingSpinnerHub from '../components/LoadingSpinnerHub.vue';
import Papa from 'papaparse';
import randomstring from 'randomstring';

pdfMake.vfsimport = pdfFonts.pdfMake.vfs;
    
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/src/VueDatePicker/style/main.scss';
    
export default {
    name: "OrdersList",
    data() {
        return {
            hideOptions: false,
            queryNumber: 0,
            query: null,
            pickedStatus: "all",
            sortName: "creationDesc",
            sortType: {
                order: "desc",
                type: "meta.created.unix"
            },
            date: {
                create: null,
                delivery: null,
                month: null
            },
            pickedDateOfDeliveryPeriodUnix: null,
            pickedDateOfMonthCreateUnix: null,
            pickedDateOfCreatePeriodUnix: null,
            pickedDateOfCreate: null,
            pickedDateOfCreateUnix: null,
            pickedDateOfDelivery: null,
            pickedDateOfDeliveryUnix: null,
            showStatusFilters: false,
            countriesCodes: [],
            countriesData: [],
            statuses:[],
            loading: false,
            searcher: {
                found: [],
                locked: false,
                phrase: "",
                lastCall: null
            },
            orders: [],
            queryLimit: 25,
            dbListener: null,
            lastLoaded: null,
            queryLimitReached: false,
            commentsModal:{
                toggle: false,
                data: null
            },
            historyModal: {
                toggle: false,
                data: null
            },
            countryName: 'all',
            manufacturerName: 'all',
            manufacturers: [],
            countries: [
                {
                    displayName: 'Polska',
                    name: 'Poland',
                    code: 'PL',
                    flagUrl: 'https://flagcdn.com/w320/pl.png',
                },
                {
                    displayName: 'Niemcy',
                    name: 'Germany',
                    code: 'DE',
                    flagUrl: 'https://flagcdn.com/w320/de.png',
                },
                {
                    displayName: 'Francja',
                    name: 'France',
                    code: 'FR',
                    flagUrl: 'https://flagcdn.com/w320/fr.png',
                },
                {
                    displayName: 'Luksemburg',
                    name: 'Luxembourg',
                    code: 'LU',
                    flagUrl: 'https://flagcdn.com/w320/lu.png',
                },
                {
                    displayName: 'Włochy',
                    name: 'Italy',
                    code: 'IT',
                    flagUrl: 'https://flagcdn.com/w320/it.png',
                },
                {
                    displayName: 'Holandia',
                    name: 'Netherlands',
                    code: 'NL',
                    flagUrl: 'https://flagcdn.com/w320/nl.png',
                },
                                    {
                    displayName: 'Belgia',
                    name: 'Belgium',
                    code: 'BE',
                    flagUrl: 'https://flagcdn.com/w320/be.png',
                },
                {
                    displayName: 'Austria',
                    name: 'Austria',
                    code: 'AT',
                    flagUrl: 'https://flagcdn.com/w320/at.png',
                },
            ],
        }
    },
    components: {
        VueDatePicker, Papa, CollectionIcon, ClipboardIcon, ClipboardCheckIcon, AnnotationIcon, SearchIcon, PlusSmIcon,  XIcon, FilterIcon, PrinterIcon,  ArrowLeftIcon, NewspaperIcon, DotsVerticalIcon,  HomeIcon, PencilAltIcon, PhotographIcon, HistoryModal, CommentsModal, EmptyState, LoadingSpinnerHub, MenuButton, MenuItem, MenuItems, Menu
    },
    async created() {
        this.moment = moment;
        moment.locale('pl')
        if(!this.$store.state.userData.permissions.admin && !this.$store.state.userData.permissions.orders)
        {
            this.$router.push("/hub")
        }
        await this.getManufacturers();
        await this.getStatuses()
        await this.getCountries()
        await this.getAllOrders()
    },
    methods:
    {
        async getManufacturers()
        {
            try {
                let manufacturers = [];
                let response = await db.collection('Manufacturers').get();
                response.forEach(doc => {
                    manufacturers.push(doc.data())
                })
                this.manufacturers = manufacturers;
            } catch (error) {
                console.log(error);
            }
        },
        customWordEnding(n)
        {
            let str = '';
            if(n === 1)
            {
                str = 'zamówienie';
            }
            else if(n > 1 && n < 5)
            {
                str = 'zamówienia';
            }
            else if(n >= 5)
            {
                str = 'zamówień';
            }
            return str;
        },
        openHistoryModal(order){
            this.historyModal.toggle = true
            this.historyModal.data = order
        },
        closeHistoryModal(){
            this.historyModal.toggle = false
            this.historyModal.data = null
        },
        openCommentsModal(order){
            this.commentsModal.toggle = true
            this.commentsModal.data = order
        },
        closeCommentsModal(){
            this.commentsModal.toggle = false
            this.commentsModal.data = null
        },
        getManufacturer(order)
        {
            const manufacturer = Object.entries(order.positions)
            let translatedListOfManufacturer = [];
            manufacturer.forEach(element => {
                if(element[0])
                {
                    if (typeof element[1].product.manufacturer === "string") {
                        element[1].product.manufacturer.trim();
                        if (!translatedListOfManufacturer.includes(element[1].product.manufacturer)) {
                            translatedListOfManufacturer.push(element[1].product.manufacturer);
                        }
                    } else {
                        element[1].product.manufacturer.name.trim();
                        if (!translatedListOfManufacturer.includes(element[1].product.manufacturer.name)) {
                            translatedListOfManufacturer.push(element[1].product.manufacturer.name);
                        }
                    }
                }
            })
            return translatedListOfManufacturer
        },
        createCommentList(order)
        {
            const products = Object.entries(order.positions)
            let translatedListOfComments = [];
            products.forEach(element => {
                if(element[0])
                {
                    translatedListOfComments.push(element[1].infos.onOrderCreation);
                }
            })

            return translatedListOfComments
        },
        createProductList(order)
        {
            const products = Object.entries(order.positions)
            let translatedListOfProducts = [];
            products.forEach(element => {
                if(element[0])
                {
                    translatedListOfProducts.push(element[1].product.name);
                }
            })

            return translatedListOfProducts
        },
        pickDateOfDelivery(event){
            let mDate = moment(event.target.value)
            if(!mDate.isValid())
            {
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Popraw datę!",
                    subheader: `Wykryto, że data jest niepoprawna.`,
                    success: false
                });
                this.pickedDateOfDelivery = null;
                this.pickedDateOfDeliveryUnix = null
            }
            else
            {
                this.pickedDateOfDeliveryUnix = mDate.unix()
                // this.pickStatus()
            }
        },
        pickDateOfCreate(event){
            let mDate = moment(event.target.value)
            if(!mDate.isValid())
            {
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Popraw datę!",
                    subheader: `Wykryto, że data jest niepoprawna.`,
                    success: false
                });
                this.pickedDateOfCreate = null;
                this.pickedDateOfCreateUnix = null
            }
            else
            {
                this.pickedDateOfCreateUnix = mDate.unix()
                // this.pickStatus()
            }
        },
        resetDateOfCreate(){
            this.hideOptions = false;
            this.pickedDateOfCreate = null;
            this.pickedDateOfCreateUnix = null;
            this.pickedDateOfCreatePeriodUnix = null;
            this.pickedDateOfMonthCreateUnix = null;
            this.date.create = null;
            this.date.month = null;
            this.date.delivery = null;
            // this.pickStatus()
        },
        resetDateOfDelivery(){
            this.hideOptions = false;
            this.pickedDateOfDelivery = null;
            this.pickedDateOfDeliveryUnix = null;
            this.pickedDateOfDeliveryPeriodUnix = null;
            this.date.create = null;
            this.date.month = null;
            this.date.delivery = null;
            // this.pickStatus()
        },
        changeSort(){
            if(this.sortName === "creationAsc"){
                this.sortType = {
                    order: "asc",
                    type: "meta.created.date"
                }
            }
            if(this.sortName === "creationDesc"){
                this.sortType = {
                    order: "desc",
                    type: "meta.created.date"
                }
            }
            if(this.sortName === "suggestedDeliveryAsc"){
                this.sortType = {
                    order: "asc",
                    type: "delivery.date.suggested.unix"
                }
            }
            if(this.sortName === "suggestedDeliveryDesc"){
                this.sortType = {
                    order: "desc",
                    type: "delivery.date.suggested.unix"
                }
            }
            // this.pickStatus()
        },
        async getAllOrders(){
            this.resetSearch()
            this.query = null;
            this.queryLimit = 25;
            if(this.dbListener !== null){
                this.dbListener()
                this.dbListener = null
            }
            this.query = db.collection("Orders")
            switch(this.pickedStatus)
            {
                case 'all':
                    this.query = this.query.where("statusId", "in", [6, 300, 404, 8])
                    break;
                case 6:
                    this.query = this.query.where('statusId', "==", 6)
                    break;
                case 8:
                    this.query = this.query.where('statusId', "==", 8)
                    break;
                case 300:
                    this.query = this.query.where('statusId', "==", 300)
                    break;
                case 404:
                    this.query = this.query.where('statusId', "==", 404)
                    break;
                default:
                    this.query = this.query
                    break;
            }

            if(this.countryName !== 'all')
            {
                this.query = this.query.where("delivery.country.name", "==", this.countryName);
            }

            if(this.manufacturerName !== 'all')
            {
                this.query = this.query.where("manufacturers", "array-contains", this.manufacturerName)
            }
            
            // if(this.pickedDateOfDeliveryUnix !== null){
            //     this.pickedDateOfDeliveryUnix = parseInt(this.pickedDateOfDeliveryUnix)
            //     let startOfDayDelivery = this.pickedDateOfDeliveryUnix
            //     let endOfDayDelivery = this.pickedDateOfDeliveryUnix+ 86399
            //     this.query = this.query.where('delivery.date.suggested.unix', ">=", startOfDayDelivery).where('delivery.date.suggested.unix', "<=",  endOfDayDelivery)
            //     this.hideOptions = true;
            // }

            // if(this.pickedDateOfCreateUnix !== null){
            //     this.pickedDateOfCreateUnix = parseInt(this.pickedDateOfCreateUnix)
            //     let startOfDay = this.pickedDateOfCreateUnix
            //     let endOfDay = this.pickedDateOfCreateUnix + 86399
            //     this.query = this.query.where('meta.created.unix', ">=", startOfDay).where('meta.created.unix', "<=", endOfDay)
            // }

            // if(this.pickedDateOfDeliveryUnix !== null && this.pickedDateOfCreateUnix !== null){
            //     this.query = this.query.orderBy(`delivery.date.suggested.unix`, `${this.sortType.order}`).orderBy(`meta.created.unix`, `${this.sortType.order}`)
            // }

            // if(this.pickedDateOfDeliveryUnix !== null && this.pickedDateOfCreateUnix === null){
            //     this.query = this.query.orderBy(`delivery.date.suggested.unix`, `${this.sortType.order}`)
            //     this.hideOptions = true;
            // }

            // if(this.pickedDateOfDeliveryUnix === null && this.pickedDateOfCreateUnix !== null){
            //     this.query = this.query.orderBy(`meta.created.unix`, `${this.sortType.order}`)
            // }

            // if(this.pickedDateOfDeliveryUnix === null && this.pickedDateOfCreateUnix === null){
            //     this.query = this.query.orderBy(`${this.sortType.type}`, `${this.sortType.order}`)
            // }
            
            if(this.pickedDateOfMonthCreateUnix !== null)
            {
                let newDate = moment.utc();
                newDate.set('month', this.pickedDateOfMonthCreateUnix.month);
                newDate.set('year', this.pickedDateOfMonthCreateUnix.year);
                let startOfMonth = moment(newDate).startOf('month').unix();
                let endOfMonth = moment(newDate).endOf('month').unix();
                this.query = this.query.where('meta.created.unix', ">=", startOfMonth).where('meta.created.unix', "<=", endOfMonth)
            }
            
            if(this.pickedDateOfDeliveryPeriodUnix !== null){
                let startOfDayDelivery = moment.unix(this.pickedDateOfDeliveryPeriodUnix[0]).startOf('day').unix();
                let endOfDayDelivery = moment.unix(this.pickedDateOfDeliveryPeriodUnix[1]).endOf('day').unix();
                this.query = this.query.where('delivery.date.suggested.unix', ">=", startOfDayDelivery).where('delivery.date.suggested.unix', "<=",  endOfDayDelivery)
                this.hideOptions = true;
            }

            if(this.pickedDateOfCreatePeriodUnix !== null){
                let startOfDay = moment.unix(this.pickedDateOfCreatePeriodUnix[0]).startOf('day').unix();
                let endOfDay = moment.unix(this.pickedDateOfCreatePeriodUnix[1]).endOf('day').unix();
                this.query = this.query.where('meta.created.unix', ">=", startOfDay).where('meta.created.unix', "<=", endOfDay)
            }
            
            if(this.pickedDateOfCreatePeriodUnix !== null && this.pickedDateOfDeliveryPeriodUnix === null)
            {
                let startOfDay = moment.unix(this.pickedDateOfCreatePeriodUnix[0]).startOf('day').unix();
                let endOfDay = moment.unix(this.pickedDateOfCreatePeriodUnix[1]).endOf('day').unix();
                this.query = this.query.where('meta.created.unix', ">=", startOfDay).where('meta.created.unix', "<=", endOfDay)
            }
            
            if(this.pickedDateOfDeliveryPeriodUnix !== null && this.pickedDateOfCreatePeriodUnix === null)
            {
                let startOfDayDelivery = moment.unix(this.pickedDateOfDeliveryPeriodUnix[0]).startOf('day').unix();
                let endOfDayDelivery = moment.unix(this.pickedDateOfDeliveryPeriodUnix[1]).endOf('day').unix();
                this.query = this.query.where('delivery.date.suggested.unix', ">=", startOfDayDelivery).where('delivery.date.suggested.unix', "<=", endOfDayDelivery)
            }
            
            if(this.pickedDateOfDeliveryPeriodUnix !== null && this.pickedDateOfCreatePeriodUnix === null){
                this.query = this.query.orderBy(`delivery.date.suggested.unix`, `${this.sortType.order}`)
                this.hideOptions = true;
                if(this.hideOptions)
                {
                    if(this.sortName === 'creationAsc')
                    {
                        this.sortName = 'suggestedDeliveryAsc';
                    }
                    else if(this.sortName === 'creationDesc')
                    {
                        this.sortName = 'suggestedDeliveryDesc';
                    }
                }
            }
            if(this.pickedDateOfDeliveryPeriodUnix === null && this.pickedDateOfCreatePeriodUnix !== null){
                this.query = this.query.orderBy(`meta.created.unix`, `${this.sortType.order}`)
            }

            if(this.pickedDateOfDeliveryPeriodUnix === null && this.pickedDateOfCreatePeriodUnix === null){
                this.query = this.query.orderBy(`${this.sortType.type}`, `${this.sortType.order}`)
            }

            this.dbListener = this.query.limit(this.queryLimit)
            .onSnapshot((querySnapshot) => {
            this.orders = [];
            if(querySnapshot.docs.length<this.queryLimit)
            {
                this.queryLimitReached = true;
            }
            else
            {
                this.queryLimitReached = false;
            }
            querySnapshot.forEach((doc) => {
                let current = doc.data()
                current.countryFlagURL = "";
                    if(current.delivery.country.code.length === 0 && current.invoice.countryCode.length > 0)
                    {
                        current.delivery.country.code = current.invoice.countryCode;
                    }
                    
                    for(let y=0; y< this.countriesData.length; y++)
                    {
                        if(this.countriesData[y].code === current.delivery.country.code)
                        {
                            current.countryFlagURL = this.countriesData[y].flagURL;
                        }
                    }
                this.orders.push(current);
                this.lastLoaded = current.meta.created.date;
            });
        });
        },
        async downloadAllOrdersAsCSV(order)
        {
            try {
                let csvOrders = [];
                if(order === undefined)
                {
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Trwa generowanie pliku CSV.",
                        subheader: `Liczba załadowanych zamówień: ${this.orders.length}`,
                        success: true
                    });
                    for(let i=0; i<this.orders.length; i++)
                    {
                        csvOrders.push(this.orders[i])
                        if(this.orders[i].meta.generated !== true || this.orders[i].meta.generated === undefined || this.orders[i].meta.generated === null)
                        {
                            await db.collection('Orders').doc(this.orders[i].id).update({
                                'meta.generated': true
                            })
                        }
                    }
                }
                else
                {
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Trwa generowanie pliku CSV.",
                        subheader: `Załadowane zamówienie: 1`,
                        success: true
                    });
                    csvOrders.push(order)
                    if(order.meta.generated !== true || order.meta.generated === undefined || order.meta.generated === null)
                    {
                        await db.collection('Orders').doc(order.id).update({
                            'meta.generated': true
                        })
                    }
                }

                const createCSV = (data) => {
                    const csvData = Papa.unparse(data, {
                        quotes: true, //or array of booleans
                        delimiter: ";",
                        newline: "\r\n",
                    });
                    return csvData
                }

                const downloadCSV = async (data) => {
                    try {
                        let filename = `FS-${randomstring.generate(6)}-${moment().format('MM-YYYY')}.csv`
                        setTimeout(() => {
                            this.$store.commit('setNotification',{
                                show: true,
                                head: "Pomyślnie wygenerowano plik z zamówieniami!",
                                subheader: `Pobrano: ${filename}`,
                                success: true
                            });
                            const blob = new Blob([data], { type: 'text/csv' });
                            const url = window.URL.createObjectURL(blob)
                            const a = document.createElement('a')
                            a.setAttribute('href', url)
                            a.setAttribute('download', filename);
                            a.click()
                        }, 2000);
                    } catch (error) {
                    console.log(error); 
                        this.$store.commit('setNotification',{
                            show: true,
                            head: "Błąd podczas generowania pliku CSV.",
                            subheader: `Spróbuj ponownie później.`,
                            success: false
                        });
                    }
                }

                let template = {
                    'Typ faktury': 'FS',
                    'Data wystawienia faktury': '',
                    'Numer faktury': '',
                    'Waluta': '',
                    'Rodzaj płatności': '',
                    'Termin płatności': '',
                    'Uwagi dot. zamówienia': '',
                    'Symbol klienta': '',
                    'Nazwa klienta': '',
                    'NIP klienta': '',
                    'Ulica klienta': '',                                    
                    'Kod pocztowy klienta': '',
                    'Miejscowość klienta': '',
                    'Kraj klienta': '',
                    'Email klienta': '',
                    'Telefon klienta': '',                                
                    'Wartość NETTO po rabacie': '',
                    'Stawka VAT': '',
                    'Wartość BRUTTO po rabacie': '',
                    'Rodzaj kartoteki': 'towar',
                    'Symbol towaru': '',
                    'Nazwa towaru': '',
                    "Ilość towaru": '',
                }

                const getOrders = async () => {
                    // do naprawienia stringow w pozycjach, jak nadal będą.
                    // for(let i=0; i<csvOrders.length; i++)
                    // {
                    //     let positions = null;
                    //     if(csvOrders[i].originName !== "Manual")
                    //     {
                    //         positions = Object.values(csvOrders[i].origin.positions);
                    //         for(let y=0; y<positions.length; y++)
                    //         {
                    //             let fixed = JSON.parse(JSON.stringify(positions[y]));
                    //             fixed.product.pricePerItemTaxIncluded = parseFloat(fixed.product.pricePerItemTaxIncluded);
                    //             fixed.product.taxRate = parseFloat(fixed.product.taxRate);
                    //             await db.collection('Orders').doc(csvOrders[i].id).update({
                    //                 [`origin.positions.${fixed.id}`]: fixed
                    //             })
                    //         }
                    //     }
                    //     else
                    //     {
                    //         positions = Object.values(csvOrders[i].positions);
                    //     }
                    // }
                    let orders = [];
                    for(let i=0; i<csvOrders.length; i++)
                    {
                        let positions = Object.values(csvOrders[i].positions);
                        
                        let groupedPositions = [];
                        for (let j = 0; j < positions.length; j++) {
                            const position = positions[j];
                            const existingPosition = groupedPositions.find(
                                p => p.product.variantId === position.product.variantId
                            );

                            if (existingPosition) {
                                existingPosition.quantity += Number(position.product.quantity);
                                existingPosition.count += 1;
                                existingPosition.priceBeforeTax = (currency(existingPosition.product.pricePerItemTaxIncluded).value / currency(`1.${existingPosition.product.taxRate}`).value);
                                existingPosition.taxRate = position.product.taxRate; // Add taxRate without summing
                                existingPosition.pricePerItemTaxIncluded += currency(position.product.pricePerItemTaxIncluded).value; 
                                positions.splice(j, 1);
                                j--; // Adjust index after splice
                            } else {
                                groupedPositions.push({ 
                                    ...position, 
                                    quantity: Number(position.product.quantity), 
                                    count: 1,
                                    priceBeforeTax: (currency(position.product.pricePerItemTaxIncluded).value / currency(`1.${position.product.taxRate}`).value),
                                    taxRate: position.product.taxRate, // Add taxRate
                                    pricePerItemTaxIncluded: currency(position.product.pricePerItemTaxIncluded).value
                                });
                            }
                        }
                      
                                    
                        let finalPositions = [];
                        for (let k = 0; k < groupedPositions.length; k++) {
                            finalPositions.push(groupedPositions[k]);
                        }
                    
                        for(let y=0; y<finalPositions.length; y++)
                        {
                            //countryName
                            let countryName = ""
                            for(let y=0; y< this.countries.length; y++)
                            {
                                if(this.countries[y].code === (csvOrders[i].origin != null ? csvOrders[i].origin.invoice != null ? csvOrders[i].origin.invoice.needed ? csvOrders[i].origin.invoice.countryCode.length > 0 ? csvOrders[i].origin.invoice.countryCode : csvOrders[i].delivery.country.code : csvOrders[i].delivery.country.code : csvOrders[i].delivery.country.code : csvOrders[i].delivery.country.code))
                                {
                                    countryName = this.countries[y].displayName;
                                }
                            };

                            let externalId = '';
                            if(csvOrders[i].originName === 'Manual' && (csvOrders[i].meta.external != null && csvOrders[i].meta.external.ids != null))
                            {
                                externalId = csvOrders[i].id + ', ' + `${csvOrders[i].meta.external != null && csvOrders[i].meta.external.ids != null ? csvOrders[i].meta.external.ids.externalOrderId + ' ' + "(" + csvOrders[i].originName + ")" : "---" + ' ' + "(" + csvOrders[i].originName + ")"}`;
                            }
                            else
                            {
                                if(csvOrders[i].origin != null && csvOrders[i].origin.meta.external.ids.externalOrderId != null && csvOrders[i].origin.meta.external.ids.externalOrderId.length > 0)
                                {
                                    externalId = csvOrders[i].id + ', ' + `${csvOrders[i].origin !== undefined ? csvOrders[i].origin.meta !== undefined ? csvOrders[i].origin.meta.external !== undefined ? csvOrders[i].origin.meta.external.ids !== undefined ? csvOrders[i].origin.meta.external.ids.externalOrderId !== undefined && csvOrders[i].origin.meta.external.ids.externalOrderId.length > 0 ? csvOrders[i].origin.meta.external.ids.externalOrderId : csvOrders[i].origin.meta.external.ids.bolOrderId !== undefined && csvOrders[i].origin.meta.external.ids.bolOrderId.length > 0 ? csvOrders[i].origin.meta.external.ids.bolOrderId : csvOrders[i].origin.meta.external.ids.hoodOrderId !== undefined && csvOrders[i].origin.meta.external.ids.hoodOrderId.length > 0 ? csvOrders[i].origin.meta.external.ids.hoodOrderId : csvOrders[i].origin.meta.external.ids.apiloOrderId !== undefined && csvOrders[i].origin.meta.external.ids.apiloOrderId.length > 0 ? csvOrders[i].origin.meta.external.ids.apiloOrderId : csvOrders[i].origin.meta.external.ids.ottoOrderId !== undefined && csvOrders[i].origin.meta.external.ids.ottoOrderId.length > 0 ? csvOrders[i].origin.meta.external.ids.ottoOrderId : "--"  : "--" : "--" : "--" : "--"} ${csvOrders[i].origin !== undefined ? csvOrders[i].origin.meta !== undefined ? csvOrders[i].origin.meta.external !== undefined ? csvOrders[i].origin.meta.external.source !== undefined ? csvOrders[i].origin.meta.external.source.name !== undefined && csvOrders[i].origin.meta.external.source.name.length > 0 ? "(" + csvOrders[i].origin.meta.external.source.name + ")" : "(" + csvOrders[i].originName + ")" : "(" + csvOrders[i].originName + ")" : "(" + csvOrders[i].originName + ")" : "(" + csvOrders[i].originName + ")" : "(" + csvOrders[i].originName + ")"}`;
                                }
                                else if(csvOrders[i].origin != null && csvOrders[i].origin.meta.external.ids.bolOrderId != null && csvOrders[i].origin.meta.external.ids.bolOrderId.length > 0)
                                {
                                    externalId = csvOrders[i].id  + ', ' + `${csvOrders[i].origin !== undefined ? csvOrders[i].origin.meta !== undefined ? csvOrders[i].origin.meta.external !== undefined ? csvOrders[i].origin.meta.external.ids !== undefined ? csvOrders[i].origin.meta.external.ids.externalOrderId !== undefined && csvOrders[i].origin.meta.external.ids.externalOrderId.length > 0 ? csvOrders[i].origin.meta.external.ids.externalOrderId : csvOrders[i].origin.meta.external.ids.bolOrderId !== undefined && csvOrders[i].origin.meta.external.ids.bolOrderId.length > 0 ? csvOrders[i].origin.meta.external.ids.bolOrderId : csvOrders[i].origin.meta.external.ids.hoodOrderId !== undefined && csvOrders[i].origin.meta.external.ids.hoodOrderId.length > 0 ? csvOrders[i].origin.meta.external.ids.hoodOrderId : csvOrders[i].origin.meta.external.ids.apiloOrderId !== undefined && csvOrders[i].origin.meta.external.ids.apiloOrderId.length > 0 ? csvOrders[i].origin.meta.external.ids.apiloOrderId : csvOrders[i].origin.meta.external.ids.ottoOrderId !== undefined && csvOrders[i].origin.meta.external.ids.ottoOrderId.length > 0 ? csvOrders[i].origin.meta.external.ids.ottoOrderId : "--"  : "--" : "--" : "--" : "--"} ${csvOrders[i].origin !== undefined ? csvOrders[i].origin.meta !== undefined ? csvOrders[i].origin.meta.external !== undefined ? csvOrders[i].origin.meta.external.source !== undefined ? csvOrders[i].origin.meta.external.source.name !== undefined && csvOrders[i].origin.meta.external.source.name.length > 0 ? "(" + csvOrders[i].origin.meta.external.source.name + ")" : "(" + csvOrders[i].originName + ")" : "(" + csvOrders[i].originName + ")" : "(" + csvOrders[i].originName + ")" : "(" + csvOrders[i].originName + ")" : "(" + csvOrders[i].originName + ")"}`;
                                }
                                else if(csvOrders[i].origin != null && csvOrders[i].origin.meta.external.ids.wayfairOrderId != null && csvOrders[i].origin.meta.external.ids.wayfairOrderId.length > 0)
                                {
                                    externalId = csvOrders[i].id  + ', ' + `${csvOrders[i].origin !== undefined ? csvOrders[i].origin.meta !== undefined ? csvOrders[i].origin.meta.external !== undefined ? csvOrders[i].origin.meta.external.ids !== undefined ? csvOrders[i].origin.meta.external.ids.externalOrderId !== undefined && csvOrders[i].origin.meta.external.ids.externalOrderId.length > 0 ? csvOrders[i].origin.meta.external.ids.externalOrderId : csvOrders[i].origin.meta.external.ids.bolOrderId !== undefined && csvOrders[i].origin.meta.external.ids.bolOrderId.length > 0 ? csvOrders[i].origin.meta.external.ids.bolOrderId : csvOrders[i].origin.meta.external.ids.hoodOrderId !== undefined && csvOrders[i].origin.meta.external.ids.hoodOrderId.length > 0 ? csvOrders[i].origin.meta.external.ids.hoodOrderId : csvOrders[i].origin.meta.external.ids.apiloOrderId !== undefined && csvOrders[i].origin.meta.external.ids.apiloOrderId.length > 0 ? csvOrders[i].origin.meta.external.ids.apiloOrderId : csvOrders[i].origin.meta.external.ids.ottoOrderId !== undefined && csvOrders[i].origin.meta.external.ids.ottoOrderId.length > 0 ? csvOrders[i].origin.meta.external.ids.ottoOrderId : "--"  : "--" : "--" : "--" : "--"} ${csvOrders[i].origin !== undefined ? csvOrders[i].origin.meta !== undefined ? csvOrders[i].origin.meta.external !== undefined ? csvOrders[i].origin.meta.external.source !== undefined ? csvOrders[i].origin.meta.external.source.name !== undefined && csvOrders[i].origin.meta.external.source.name.length > 0 ? "(" + csvOrders[i].origin.meta.external.source.name + ")" : "(" + csvOrders[i].originName + ")" : "(" + csvOrders[i].originName + ")" : "(" + csvOrders[i].originName + ")" : "(" + csvOrders[i].originName + ")" : "(" + csvOrders[i].originName + ")"}`;
                                }
                                else if(csvOrders[i].origin != null && csvOrders[i].origin.meta.external.ids.hoodOrderId != null && csvOrders[i].origin.meta.external.ids.hoodOrderId.length > 0)
                                {
                                    externalId = csvOrders[i].id  + ', ' + `${csvOrders[i].origin !== undefined ? csvOrders[i].origin.meta !== undefined ? csvOrders[i].origin.meta.external !== undefined ? csvOrders[i].origin.meta.external.ids !== undefined ? csvOrders[i].origin.meta.external.ids.externalOrderId !== undefined && csvOrders[i].origin.meta.external.ids.externalOrderId.length > 0 ? csvOrders[i].origin.meta.external.ids.externalOrderId : csvOrders[i].origin.meta.external.ids.bolOrderId !== undefined && csvOrders[i].origin.meta.external.ids.bolOrderId.length > 0 ? csvOrders[i].origin.meta.external.ids.bolOrderId : csvOrders[i].origin.meta.external.ids.hoodOrderId !== undefined && csvOrders[i].origin.meta.external.ids.hoodOrderId.length > 0 ? csvOrders[i].origin.meta.external.ids.hoodOrderId : csvOrders[i].origin.meta.external.ids.apiloOrderId !== undefined && csvOrders[i].origin.meta.external.ids.apiloOrderId.length > 0 ? csvOrders[i].origin.meta.external.ids.apiloOrderId : csvOrders[i].origin.meta.external.ids.ottoOrderId !== undefined && csvOrders[i].origin.meta.external.ids.ottoOrderId.length > 0 ? csvOrders[i].origin.meta.external.ids.ottoOrderId : "--"  : "--" : "--" : "--" : "--"} ${csvOrders[i].origin !== undefined ? csvOrders[i].origin.meta !== undefined ? csvOrders[i].origin.meta.external !== undefined ? csvOrders[i].origin.meta.external.source !== undefined ? csvOrders[i].origin.meta.external.source.name !== undefined && csvOrders[i].origin.meta.external.source.name.length > 0 ? "(" + csvOrders[i].origin.meta.external.source.name + ")" : "(" + csvOrders[i].originName + ")" : "(" + csvOrders[i].originName + ")" : "(" + csvOrders[i].originName + ")" : "(" + csvOrders[i].originName + ")" : "(" + csvOrders[i].originName + ")"}`;
                                }
                                else if(csvOrders[i].origin != null && csvOrders[i].origin.meta.external.ids.shopOrderId != null && csvOrders[i].origin.meta.external.ids.shopOrderId.length > 0)
                                {
                                    externalId = csvOrders[i].id  + ', ' + `${csvOrders[i].origin !== undefined ? csvOrders[i].origin.meta !== undefined ? csvOrders[i].origin.meta.external !== undefined ? csvOrders[i].origin.meta.external.ids !== undefined ? csvOrders[i].origin.meta.external.ids.externalOrderId !== undefined && csvOrders[i].origin.meta.external.ids.externalOrderId.length > 0 ? csvOrders[i].origin.meta.external.ids.externalOrderId : csvOrders[i].origin.meta.external.ids.bolOrderId !== undefined && csvOrders[i].origin.meta.external.ids.bolOrderId.length > 0 ? csvOrders[i].origin.meta.external.ids.bolOrderId : csvOrders[i].origin.meta.external.ids.hoodOrderId !== undefined && csvOrders[i].origin.meta.external.ids.hoodOrderId.length > 0 ? csvOrders[i].origin.meta.external.ids.hoodOrderId : csvOrders[i].origin.meta.external.ids.apiloOrderId !== undefined && csvOrders[i].origin.meta.external.ids.apiloOrderId.length > 0 ? csvOrders[i].origin.meta.external.ids.apiloOrderId : csvOrders[i].origin.meta.external.ids.ottoOrderId !== undefined && csvOrders[i].origin.meta.external.ids.ottoOrderId.length > 0 ? csvOrders[i].origin.meta.external.ids.ottoOrderId : "--"  : "--" : "--" : "--" : "--"} ${csvOrders[i].origin !== undefined ? csvOrders[i].origin.meta !== undefined ? csvOrders[i].origin.meta.external !== undefined ? csvOrders[i].origin.meta.external.source !== undefined ? csvOrders[i].origin.meta.external.source.name !== undefined && csvOrders[i].origin.meta.external.source.name.length > 0 ? "(" + csvOrders[i].origin.meta.external.source.name + ")" : "(" + csvOrders[i].originName + ")" : "(" + csvOrders[i].originName + ")" : "(" + csvOrders[i].originName + ")" : "(" + csvOrders[i].originName + ")" : "(" + csvOrders[i].originName + ")"}`;
                                }
                                else
                                {
                                    externalId = csvOrders[i].id  + ', ' + `${csvOrders[i].origin !== undefined ? csvOrders[i].origin.meta !== undefined ? csvOrders[i].origin.meta.external !== undefined ? csvOrders[i].origin.meta.external.ids !== undefined ? csvOrders[i].origin.meta.external.ids.externalOrderId !== undefined && csvOrders[i].origin.meta.external.ids.externalOrderId.length > 0 ? csvOrders[i].origin.meta.external.ids.externalOrderId : csvOrders[i].origin.meta.external.ids.bolOrderId !== undefined && csvOrders[i].origin.meta.external.ids.bolOrderId.length > 0 ? csvOrders[i].origin.meta.external.ids.bolOrderId : csvOrders[i].origin.meta.external.ids.hoodOrderId !== undefined && csvOrders[i].origin.meta.external.ids.hoodOrderId.length > 0 ? csvOrders[i].origin.meta.external.ids.hoodOrderId : csvOrders[i].origin.meta.external.ids.apiloOrderId !== undefined && csvOrders[i].origin.meta.external.ids.apiloOrderId.length > 0 ? csvOrders[i].origin.meta.external.ids.apiloOrderId : csvOrders[i].origin.meta.external.ids.ottoOrderId !== undefined && csvOrders[i].origin.meta.external.ids.ottoOrderId.length > 0 ? csvOrders[i].origin.meta.external.ids.ottoOrderId : "--"  : "--" : "--" : "--" : "--"} ${csvOrders[i].origin !== undefined ? csvOrders[i].origin.meta !== undefined ? csvOrders[i].origin.meta.external !== undefined ? csvOrders[i].origin.meta.external.source !== undefined ? csvOrders[i].origin.meta.external.source.name !== undefined && csvOrders[i].origin.meta.external.source.name.length > 0 ? "(" + csvOrders[i].origin.meta.external.source.name + ")" : "(" + csvOrders[i].originName + ")" : "(" + csvOrders[i].originName + ")" : "(" + csvOrders[i].originName + ")" : "(" + csvOrders[i].originName + ")" : "(" + csvOrders[i].originName + ")"}`;
                                }
                            }

                            template = {
                                'Typ faktury': 'FS',
                                'Data wystawienia faktury': moment().format('YYYY-MM-DD'),
                                'Numer faktury': csvOrders[i].id,
                                'Waluta': finalPositions[y].product.currency,
                                'Rodzaj płatności': csvOrders[i].payment.cashOnDelivery ? 'Gotówka' : 'Kredyt kupiecki',
                                'Termin płatności': moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
                                'Uwagi dot. zamówienia': externalId,
                                'Symbol klienta': csvOrders[i].origin?.invoice?.needed ? (csvOrders[i].origin.invoice.company || csvOrders[i].origin.invoice.fullname || csvOrders[i].client.name) : csvOrders[i].client.name,
                                'Nazwa klienta': csvOrders[i].origin?.invoice?.needed ? (csvOrders[i].origin.invoice.fullname || csvOrders[i].client.name) : csvOrders[i].client.name,
                                'NIP klienta': csvOrders[i].origin?.invoice?.needed ? csvOrders[i].origin.invoice.taxId : csvOrders[i].invoice?.needed ? csvOrders[i].invoice.taxId : '', 
                                'Ulica klienta': csvOrders[i].origin?.invoice?.address?.length > 0 ? csvOrders[i].origin.invoice.address : csvOrders[i].invoice?.needed && csvOrders[i].invoice.address?.length > 0 ? csvOrders[i].invoice.address : csvOrders[i].delivery.address,                                    
                                'Kod pocztowy klienta': csvOrders[i].origin?.invoice?.needed && csvOrders[i].origin.invoice.postCode.length > 0 ? csvOrders[i].origin.invoice.postCode : csvOrders[i].invoice?.needed && csvOrders[i].invoice.postCode.length > 0 ? csvOrders[i].invoice.postCode : csvOrders[i].delivery.postCode.length > 0 ? csvOrders[i].delivery.postCode : '',
                                'Miejscowość klienta': csvOrders[i].origin?.contact?.city || csvOrders[i].delivery.city || csvOrders[i].client.city || '',
                                'Kraj klienta': countryName,
                                'Email klienta': csvOrders[i].origin?.contact?.email?.length > 0 ? csvOrders[i].origin.contact.email : csvOrders[i].client.email,
                                'Telefon klienta': csvOrders[i].origin?.contact?.phone?.length > 0 ? csvOrders[i].origin.contact.phone : csvOrders[i].client.phone?.full?.length > 0 ? csvOrders[i].client.phone.full : '',                                
                                'Wartość NETTO po rabacie': currency(finalPositions[y].priceBeforeTax).value,
                                'Stawka VAT': finalPositions[y].taxRate,
                                'Wartość BRUTTO po rabacie': finalPositions[y].pricePerItemTaxIncluded,
                                'Rodzaj kartoteki': 'towar',
                                'Symbol towaru': await this.createSymbolForProducts(finalPositions[y]),
                                'Nazwa towaru': finalPositions[y].product.name,
                                "Ilość towaru": finalPositions[y].quantity,
                            }
                            orders.push(template)
                        }
                    }
                    const csvdata = createCSV(orders);
                    downloadCSV(csvdata);
                }
                getOrders();
            }
            catch (error)
            {
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Generowanie pliku nie powiodło się!",
                    subheader: `Wystąpił nieoczekiwany błąd.`,
                    success: false
                });
                console.log(error);
            }
        },
        async createSymbolForProducts(order)
        {
            let translatedListOfProducts = [];
            if(order.product.id != null)
            {
                const product = (await db.collection('Products').doc(order.product.id).get()).data();
                if(product != null)
                {
                    const variants = Object.entries(product.variants);
                    for(let i=0; i<variants.length; i++)
                    {
                        if(variants[i][1].id === order.product.variantId)
                        {
                            if(variants[i][1].subiekt !== undefined)
                            {
                                translatedListOfProducts.push(variants[i][1].subiekt)
                            }
                        }
                    }
                }
                else
                {
                    translatedListOfProducts.push(randomstring.generate(6))
                }
            }
            else
            {
                translatedListOfProducts.push(randomstring.generate(6))
            }
            return translatedListOfProducts
        },
        printLabel(order){
            var docDefinition = {
                pageSize: 'A6',
                pageMargins: [10, 35, 10, 0 ],
                content: [
                    // {text: 'Zamówienie', fontSize: 14, bold:true, alignment: 'center'},
                    // {text: `${order.id}`, fontSize: 12, bold:true, alignment: 'center'},
                    // {text: '', margin: [0,4,0,4]},
                    // {text: [{text: 'Wartość zamówienia: ', bold:true},`${this.totalPrice(order.positions,order.positions[1].product.currency,order.delivery.price)}`], fontSize: 9, alignment: 'center'},
                    // {text: '', margin: [0,1,0,1]},
                    // {text: [{text: 'Ilość pozycji w zamówieniu: ', bold:true},`${this.amountOfItemsForLabel(order.positions)}`], fontSize: 9, alignment: 'center'},
                    // {text: '', margin: [0,8,0,8]},
                    // {text: 'Klient: ', bold:true, fontSize: 9, alignment: 'center'},
                    // {text: '', margin: [0,1,0,1]},
                    // {
                    //     table: {
                    //     widths: ['50%','50%'],
                    //     body: [
                    //         [
                    //             {text: {text: 'Imię i nazwisko:', bold:true}, fontSize: 9, alignment: 'center', border: [false, false, false, false]},
                    //             {text: {text: 'Firma: ', bold:true}, fontSize: 9, alignment: 'center', border: [false, false, false, false]},
                    //         ],
                    //         [
                    //             {text: {text: `${order.client.name.length > 0 ? order.client.name.substring(0,25) + `${order.client.name.length > 25 ? '...' : ''}` : order.invoice.fullname.length > 0 ? order.invoice.fullname.substring(0,25) + `${order.invoice.fullname.length > 25 ? '...' : ''}` : 'Brak'}`}, fontSize: 7, alignment: 'center', border: [false, false, false, false]},
                    //             {text: {text: `${order.client.company.length > 0 ? order.client.company.substring(0,25) + `${order.client.company.length > 25 ? '...' : ''}` : order.invoice.company.length > 0 ? order.invoice.company.substring(0,25) + `${order.invoice.company.length > 25 ? '...' : ''}` : order.delivery.company.length > 0 ? order.delivery.company.substring(0,25) + `${order.delivery.company.length > 25 ? '...' : ''}` : '--'}`}, fontSize: 7, alignment: 'center', border: [false, false, false, false]},
                    //         ]
                    //     ]}
                    // },
                    // {
                    //     table: {
                    //     widths: ['50%','50%'],
                    //     body: [
                    //         [
                    //             {text: {text: 'E-mail:', bold:true}, fontSize: 9, alignment: 'center', border: [false, false, false, false]},
                    //             {text: {text: 'Numer telefonu: ', bold:true}, fontSize: 9, alignment: 'center', border: [false, false, false, false]},
                    //         ],
                    //         [
                    //             {text: {text: `${order.client.email.length > 0 ? order.client.email.substring(0,25) + `${order.client.email.length > 25 ? '...' : ''}` : 'Brak'}`}, fontSize: 7, alignment: 'center', border: [false, false, false, false]},
                    //             {text: {text: `${order.client.phone.full.length > 0 ? order.client.phone.full : 'Brak'}`}, fontSize: 7, alignment: 'center', border: [false, false, false, false]},
                    //         ]
                    //     ]}
                    // },
                    // {text: '', margin: [0,8,0,8]},
                    // {text: 'Dostawa: ', bold:true, fontSize: 9, alignment: 'center'},
                    // {text: '', margin: [0,1,0,1]},
                    // {
                    //     table: {
                    //     widths: ['50%','50%'],
                    //     body: [
                    //         [
                    //             {text: {text: 'Metoda:', bold:true}, fontSize: 9, alignment: 'center', border: [false, false, false, false]},
                    //             {text: {text: 'Kraj dostawy: ', bold:true}, fontSize: 9, alignment: 'center', border: [false, false, false, false]},
                    //         ],
                    //         [
                    //             {text: {text: `${order.delivery.method.length > 0 ? order.delivery.method.substring(0,25) + `${order.delivery.method.length > 25 ? '...' : ''}` : '--'}`}, fontSize: 7, alignment: 'center', border: [false, false, false, false]},
                    //             {text: {text: `${order.delivery.country.name.length > 0 ? order.delivery.country.name.substring(0,25) + `${order.delivery.country.name.length > 25 ? '...' : ''}` : '--'}`}, fontSize: 7, alignment: 'center', border: [false, false, false, false]},
                    //         ]
                    //     ]}
                    // },
                    // {
                    //     table: {
                    //     widths: ['50%','50%'],
                    //     body: [
                    //         [
                    //             {text: {text: 'Adres dostawy:', bold:true}, fontSize: 9, alignment: 'center', border: [false, false, false, false]},
                    //             {text: {text: 'Miasto dostawy:', bold:true}, fontSize: 9, alignment: 'center', border: [false, false, false, false]},
                    //         ],
                    //         [
                    //             {text: {text: `${order.delivery.address.length > 0 ? order.delivery.address.substring(0,25) + `${order.delivery.address.length > 25 ? '...' : ''}` : '--'}`}, fontSize: 7, alignment: 'center', border: [false, false, false, false]},
                    //             {text: {text: `${order.delivery.postCode.length > 0 ? order.delivery.postCode.substring(0,10) + `${order.delivery.postCode.length > 10 ? '...' : ''}` : ''}${order.delivery.postCode.length > 0 && order.delivery.city.length > 0 ? ', ' : order.delivery.postCode.length === 0 && order.delivery.city.length === 0 ? '--' : ''}${order.delivery.city.length > 0 ? order.delivery.city.substring(0,20) + `${order.delivery.city.length > 20 ? '...' : ''}` : ''}`}, fontSize: 7, alignment: 'center', border: [false, false, false, false]},
                    //         ]
                    //     ]}
                    // },
                    // {text: '', margin: [0,10,0,10]},
                    // { qr: `${order.id}`, fit: '115', version: 3,  alignment: 'center' },
                ]
            };
            let address = []
            if(order.delivery.address.length > 0){
                address.push(order.delivery.address)
            }
            if(order.delivery.postCode.length > 0){
                address.push(order.delivery.postCode)
            }
            if(order.delivery.city.length > 0){
                address.push(order.delivery.city)
            }
            if(order.delivery.country.name.length > 0){
                address.push(order.delivery.country.name)
            }
            moment.locale('pl');
            let date = moment().format('L LT')

            for(let i = 0; i < Object.keys(order.positions).length; i++) {
                for(let y = 0; y < order.positions[i+1].product.amountOfPackages; y++) {
                    let template = [
                    // {text: 'Zamówienie', fontSize: 14, pageBreak: 'before', bold:true, alignment: 'center'},
                    // {text: `${order.id}`, fontSize: 12, bold:true, alignment: 'center'},
                    // {text: '', margin: [0,10,0,10]},
                    // {text: [{text: 'Numer pozycji: ', bold:true},`${order.positions[i+1].id}`], fontSize: 9, alignment: 'center'},
                    // {text: '', margin: [0,1,0,1]},
                    // {text: [{text: 'Ilość pozycji w zamówieniu: ', bold:true},`${this.amountOfItemsForLabel(order.positions)}`], fontSize: 9, alignment: 'center'},
                    // {text: '', margin: [0,14,0,14]},
                    // {text: {text: 'Nazwa produktu: ', bold:true}, fontSize: 9, alignment: 'center'},
                    // {text: '', margin: [0,1,0,1]},
                    // {text: {text: `${order.positions[i+1].product.name.length > 0 ? order.positions[i+1].product.name.substring(0,40) + `${order.positions[i+1].product.name.length > 40 ? '...' : ''}` : '--'}`, bold:true}, fontSize: 10, alignment: 'center'},
                    // {text: '', margin: [0,14,0,14]},
                    // {text: 'Klient: ', bold:true, fontSize: 9, alignment: 'center'},
                    // {text: '', margin: [0,1,0,1]},
                    // {
                    //     table: {
                    //     widths: ['50%','50%'],
                    //     body: [
                    //         [
                    //             {text: {text: 'Imię i nazwisko:', bold:true}, fontSize: 9, alignment: 'center', border: [false, false, false, false]},
                    //             {text: {text: 'Firma: ', bold:true}, fontSize: 9, alignment: 'center', border: [false, false, false, false]},
                    //         ],
                    //         [
                    //             {text: {text: `${order.client.name.length > 0 ? order.client.name.substring(0,25) + `${order.client.name.length > 25 ? '...' : ''}` : order.invoice.fullname.length > 0 ? order.invoice.fullname.substring(0,25) + `${order.invoice.fullname.length > 25 ? '...' : ''}` : 'Brak'}`}, fontSize: 7, alignment: 'center', border: [false, false, false, false]},
                    //                 {text: {text: `${order.client.company.length > 0 ? order.client.company.substring(0,25) + `${order.client.company.length > 25 ? '...' : ''}` : order.invoice.company.length > 0 ? order.invoice.company.substring(0,25) + `${order.invoice.company.length > 25 ? '...' : ''}` : order.delivery.company.length > 0 ? order.delivery.company.substring(0,25) + `${order.delivery.company.length > 25 ? '...' : ''}` : '--'}`}, fontSize: 7, alignment: 'center', border: [false, false, false, false]},
                    //         ]
                    //     ]}
                    // },
                    // {
                    //     table: {
                    //     widths: ['50%','50%'],
                    //     body: [
                    //         [
                    //             {text: {text: 'E-mail:', bold:true}, fontSize: 9, alignment: 'center', border: [false, false, false, false]},
                    //             {text: {text: 'Numer telefonu: ', bold:true}, fontSize: 9, alignment: 'center', border: [false, false, false, false]},
                    //         ],
                    //         [
                    //             {text: {text: `${order.client.email.length > 0 ? order.client.email.substring(0,25) + `${order.client.email.length > 25 ? '...' : ''}` : 'Brak'}`}, fontSize: 7, alignment: 'center', border: [false, false, false, false]},
                    //             {text: {text: `${order.client.phone.full.length > 0 ? order.client.phone.full : 'Brak'}`}, fontSize: 7, alignment: 'center', border: [false, false, false, false]},
                    //         ]
                    //     ]}
                    // },
                    
                    // {text: '', margin: [0,18,0,18]},
                    // { qr: `${order.id}:${order.positions[i+1].id}_${y+1}`, fit: '115', version: 3,  alignment: 'center' },
                    {text: 'Zamówienie', fontSize: 14, bold:true, alignment: 'center'},
                    {text: `${order.id}`, fontSize: 12, bold:true, alignment: 'center'},
                    {text: '', margin: [0,4,0,4]},
                    {text: [{text: 'Produkt: ', bold:true},`${order.positions[i+1].id} z ${this.amountOfItemsForLabel(order.positions)}`], fontSize: 9, alignment: 'center'},
                    {text: '', margin: [0,9,0,9]},
                    {text: {text: 'Nazwa produktu: ', bold:true}, fontSize: 9, alignment: 'center'},
                    {text: '', margin: [0,1,0,1]},
                    {text: {text: `${order.positions[i+1].product.name.length > 0 ? order.positions[i+1].product.name.substring(0,40) + `${order.positions[i+1].product.name.length > 40 ? '...' : ''}` : '--'}`, bold:true}, fontSize: 10, alignment: 'center'},
                    {text: '', margin: [0,9,0,9]},
                    {text: 'Klient: ', bold:true, fontSize: 9, alignment: 'center'},
                    {text: '', margin: [0,1,0,1]},
                    {
                        table: {
                        widths: ['50%','50%'],
                        body: [
                            [
                                {text: {text: 'Imię i nazwisko:', bold:true}, fontSize: 9, alignment: 'center', border: [false, false, false, false]},
                                {text: {text: 'Firma: ', bold:true}, fontSize: 9, alignment: 'center', border: [false, false, false, false]},
                            ],
                            [
                                {text: {text: `${order.client.name.length > 0 ? order.client.name.substring(0,25) + `${order.client.name.length > 25 ? '...' : ''}` : order.invoice.fullname.length > 0 ? order.invoice.fullname.substring(0,25) + `${order.invoice.fullname.length > 25 ? '...' : ''}` : 'Brak'}`}, fontSize: 7, alignment: 'center', border: [false, false, false, false]},
                                {text: {text: `${order.client.company.length > 0 ? order.client.company.substring(0,25) + `${order.client.company.length > 25 ? '...' : ''}` : order.invoice.company.length > 0 ? order.invoice.company.substring(0,25) + `${order.invoice.company.length > 25 ? '...' : ''}` : order.delivery.company.length > 0 ? order.delivery.company.substring(0,25) + `${order.delivery.company.length > 25 ? '...' : ''}` : '--'}`}, fontSize: 7, alignment: 'center', border: [false, false, false, false]},
                            ]
                        ]}
                    },
                    {
                        table: {
                        widths: ['50%','50%'],
                        body: [
                            [
                                {text: {text: 'E-mail:', bold:true}, fontSize: 9, alignment: 'center', border: [false, false, false, false]},
                                {text: {text: 'Numer telefonu: ', bold:true}, fontSize: 9, alignment: 'center', border: [false, false, false, false]},
                            ],
                            [
                                {text: {text: `${order.client.email.length > 0 ? order.client.email.substring(0,25) + `${order.client.email.length > 25 ? '...' : ''}` : 'Brak'}`}, fontSize: 7, alignment: 'center', border: [false, false, false, false]},
                                {text: {text: `${order.client.phone.full.length > 0 ? order.client.phone.full : 'Brak'}`}, fontSize: 7, alignment: 'center', border: [false, false, false, false]},
                            ]
                        ]}
                    },
                    {text: '', margin: [0,9,0,9]},
                    {text: 'Dostawa: ', bold:true, fontSize: 9, alignment: 'center'},
                    {text: '', margin: [0,1,0,1]},
                    {text: {text: `${address.toString().substring(0,120)}${address.toString().length > 120 ? '...' : ''}`}, fontSize: 7, alignment: 'center', border: [false, false, false, false]},
                    {text: '', margin: [0,9,0,9]},
                    {
                        table: {
                        widths: ['50%','50%'],
                        body: [
                            [
                                { qr: `${order.id}:${order.positions[i+1].id}_${y+1}`, fit: '115', version: 3,  alignment: 'center', border: [false, false, false, false]},
                                {
                                    table: {
                                    widths: ['100%'],
                                    body: [
                                        [
                                            {text: {text: 'Numer paczki:', bold:true}, fontSize: 9, alignment: 'center', border: [false, false, false, false]},
                                        ],
                                        [
                                            {text: {text: `${y+1} z ${order.positions[i+1].product.amountOfPackages}`, bold: true}, fontSize: 16, alignment: 'center', border: [false, false, false, false]},
                                        ]
                                    ]}, border: [false, false, false, false], margin: [0,24,0,0]
                                },
                            ]
                        ]}
                    },
                    {text: '', margin: [0,12,0,12]},
                    {text: [{text: 'Wygenerowano: '},`${date}`], fontSize: 7, alignment: 'right', pageBreak: `${parseFloat(order.positions[i+1].id) === parseFloat(this.amountOfItemsForLabel(order.positions)) && parseFloat(y+1) === parseFloat(order.positions[i+1].product.amountOfPackages) ? '' : 'after'}`},
                    ]
                    docDefinition.content.push(...template)
                }
            }
            pdfMake.createPdf(docDefinition).download(`Zamowienie_${order.id}.pdf`);
        },
        showFilters(){
                this.showStatusFilters = !this.showStatusFilters
                if(!this.showStatusFilters){
                    this.pickedStatus = 'all';
                    this.countryName = 'all';
                    this.manufacturerName = 'all';
                    this.pickedDateOfCreate = null;
                    this.pickedDateOfCreateUnix = null
                    this.pickedDateOfDelivery = null;
                    this.pickedDateOfDeliveryUnix = null

                    this.sortName = "creationDesc",
                    this.sortType = {
                        order: "desc",
                        type: "meta.created.date"
                    }
                    this.pickStatus()
                }
        },
        pickStatus(){
            this.dbListener()
            this.getAllOrders()
        },
        getStatusById(id){
            let currentStatus = {
                name: "Brak",
                info: "Brak",
                id: null
            }
            this.statuses.forEach((status) => {
                if(status.id === id){
                    currentStatus = {
                        name: status.name,
                        info: status.info,
                        id: status.id
                    }
                }
            })
            return currentStatus
        },
        async getStatuses(){
            let res = await db.collection("OrderStatuses").doc("StatusList").get()
            let resData = res.data()
            this.statuses = resData.list
        },
        orderPlacedDateFromUNIX(unix)
        {
            if(unix === undefined) return "--"
            if(unix === null) return "--"
            if(unix === 0) return "Brak"
            let date = moment(unix * 1000).format("DD.MM.YY HH:mm");
            return date;
        },
        amountOfItems(positions)
        {
            let posAm = 0;
            let items = 0;
            for (const pos in positions) {
                posAm = posAm +1
                items = items + positions[pos].product.quantity;
            }

            return `${posAm} poz. (${items} przedmiotów)`
        },
        amountOfItemsForLabel(positions)
        {
            let posAm = 0;
            let items = 0;
            for (const pos in positions) {
                posAm = posAm +1
                items = items + positions[pos].product.quantity;
            }

            return `${posAm}`
        },
        totalPrice(positions, currency, deliveryPrice)
        {
            let totalPrice = 0;
            for (const pos in positions) {
                totalPrice = totalPrice + (positions[pos].product.quantity * positions[pos].product.pricePerItemTaxIncluded)
            }
            if(deliveryPrice > 0)
            {
                totalPrice = totalPrice + deliveryPrice;
            }
            return `${totalPrice} ${currency}`
        },
        async getCountries()
        {
            this.countriesData = [];
            const res = await axios.get(`${this.$store.state.apiLink}/data/getCountries`)
            if(res.data.status === 'SUCCESS')
            {
                let countries = res.data.countries;
                countries.forEach(country => {
                    this.countriesData.push({code: country.countryCode, flagURL: country.flagURL});
                });
            }
        },
        resetSearch()
        {
            this.searcher.found = [];
            this.searcher.lastCall = null;
            this.searcher.phrase = "";
            this.searcher.locked = false
        },
        async search()
        {
            if(this.searcher.phrase.length > 0)
            {
                this.searcher.locked = true;
                const res = await axios.post(`${this.$store.state.apiLink}/search`, {
                    index: "jan-art-orders",
                    collection: "Orders",
                    phrase: this.searcher.phrase,
                    searchFields: ['client.name', 'client.company', 'delivery.name', 'delivery.company', 'id', 'invoice.fullname', 'delivery.postCode', 'externalId'],
                    archive: true,
                })
                if(res.data.success)
                {
                    let allFound = []
                    res.data.found.forEach((found) => {
                    let current = found
                    current.countryFlagURL = "";
                        if(current.delivery.country.code.length === 0 && current.invoice.countryCode.length > 0)
                        {
                            current.delivery.country.code = current.invoice.countryCode;
                        }
                        for(let y=0; y< this.countriesData.length; y++)
                        {
                            if(this.countriesData[y].code === current.delivery.country.code)
                            {
                                current.countryFlagURL = this.countriesData[y].flagURL;
                            }
                        }
                        allFound.push(current);
                    });
                    this.searcher.found = allFound;
                    if(res.data.found.length > 0)
                    {
                        this.searcher.lastCall = res.data.data;
                        this.dbListener();
                        this.$store.commit('setNotification',{
                            show: true,
                            head: "Wyszukiwanie zakończone!",
                            subheader: `Znaleziono ${res.data.found.length} zamówień.`,
                            success: true
                        });

                    }else{
                        this.$store.commit('setNotification',{
                            show: true,
                            head: "Nie znaleziono wyników!",
                            subheader: `Spróbuj wyszukać inną frazę.`,
                            success: false
                        });
                    }

                }
                else
                {
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Wyszukiwanie zakończone niepowodzeniem!",
                        subheader: `Coś poszło nie tak...`,
                        success: false
                    });
                }
                setTimeout(()=>
                {
                    // this.searcher.phrase = "";
                    this.searcher.locked = false;
                }, 3000)
            }
            else
            {
                return;
            }
        },
        continueListening()
        {
            this.queryLimit = this.queryLimit + 25;
            this.dbListener = this.query.limit(this.queryLimit)
            .onSnapshot((querySnapshot) => {
                this.orders = [];
                if(querySnapshot.docs.length<this.queryLimit)
                {
                    this.queryLimitReached = true;
                }
                else
                {
                    this.queryLimitReached = false;
                }
                querySnapshot.forEach((doc) => {
                    let current = doc.data()
                    current.countryFlagURL = "";
                    if(current.delivery.country.code.length === 0 && current.invoice.countryCode.length > 0)
                    {
                        current.delivery.country.code = current.invoice.countryCode;
                    }
                    
                    for(let y=0; y< this.countriesData.length; y++)
                    {
                        if(this.countriesData[y].code === current.delivery.country.code)
                        {
                            current.countryFlagURL = this.countriesData[y].flagURL;
                        }
                    }
                    this.orders.push(current);
                    this.lastLoaded = current.meta.created.date;
                });
            });
        },
        loadMoreOrders()
        {
            this.dbListener();
            this.continueListening();      
        },
        copyToClipboard(id)
        {
            navigator.clipboard.writeText(id).then(()=>
            {
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Skopiowano do schowka!",
                    subheader: ``,
                    success: true
                });
            })

        },
    },
    watch: {
      'date.create': function(val, oldVal) {
        if(val != null)
        {
            this.pickedDateOfCreatePeriodUnix = [];
            val.forEach(date => {
                let unix = moment(date).unix();
                this.pickedDateOfCreatePeriodUnix.push(unix);
            });
        }
      },  
      'date.delivery': function(val, oldVal) {
        if(val != null)
        {
            this.pickedDateOfDeliveryPeriodUnix = [];
            val.forEach(date => {
                let unix = moment(date).unix();
                this.pickedDateOfDeliveryPeriodUnix.push(unix);
            });
        };
      },  
      'date.month': function(val, oldVal) {
        if(val != null)
        {
            this.pickedDateOfMonthCreateUnix = val;
        };
      },  
    },
    beforeUnmount() {
        if(this.dbListener !== null){
            this.dbListener();
        }
    }
}
</script>
<style lang="scss" scoped>
    .fade-enter-active{
    transition: opacity 1s ease;
    }
    .fade-leave-active{
        transition: opacity 0s;
        display: none;
    }

    .fade-enter-from,
    .fade-leave-to {
    opacity: 0;
    }

    .fade2-enter-active,
    .fade2-leave-active {
    transition: opacity 0.5s ease;
    }

    .fade2-enter-from,
    .fade2-leave-to {
    opacity: 0;
    }
</style>