<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10">
      <div class="fixed inset-0 bg-gray-50 bg-opacity-50" />

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
              <div class="pointer-events-auto w-screen max-w-md">
                <div class="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                  <div class="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
                    <div class="px-4 sm:px-6">
                      <div class="flex items-start justify-between">
                        <DialogTitle class="text-lg font-medium text-gray-900"> Wybierz atrybuty </DialogTitle>
                        <div class="ml-3 flex h-7 items-center">
                          <button type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500" @click="open = false">
                            <span class="sr-only">Close panel</span>
                            <XIcon class="h-6 w-6" aria-hidden="true" @click.prevent="closeModal" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="relative mt-6 flex-1 px-4 sm:px-6" >
                        <!-- Replace with your content -->
                        <!-- <loadingSpinner v-if="loading"></loadingSpinner> -->
                        <div v-if="!loading && attribGroups.length>0">
                            <Disclosure as="div" v-for="attrib in attribGroups" :key="attrib.id" class="border-b border-gray-200 py-6" v-slot="{ open }">
                                <h3 class="-my-3 flow-root select-none">
                                    <DisclosureButton class="py-3 bg-white w-full flex items-center justify-between text-sm text-gray-400 hover:text-gray-500">
                                        <span class="font-medium text-gray-900 cursor-pointer hover:opacity-40">
                                        {{attrib.name}} <span class="text-xs opacity-40" v-if="attrib.list.length === 0">(Brak wartości)</span>
                                        </span>
                                        <span class="ml-6 flex items-center cursor-pointer">
                                            <PlusSmIcon v-if="!open" class="h-5 w-5" aria-hidden="true" />
                                            <MinusSmIcon v-else class="h-5 w-5" aria-hidden="true" />
                                        </span>
                                    </DisclosureButton>
                                </h3>
                                <DisclosurePanel class="pt-6">
                                    <div class="space-y-4">
                                        <div class="text-xs opacity-40 select-none" v-if="attrib.list.length === 0">Ten atrybut nie ma wartości. <router-link :to="'/dashboard/products/attributes/modify?attrib='+attrib.id" class="text-blue-600 opacity-100 underline" target="_blank">Dodaj je tutaj.</router-link></div>
                                        <div v-for="(option) in attrib.list" :key="option.id" class="flex items-center">
                                            <CheckIcon class="h-5 w-5 text-blue-400" v-if="option.checked"></CheckIcon>
                                            <span :class="{'ml-3 text-sm text-gray-600 cursor-pointer select-none hover:text-blue-500': true, 'text-blue-600': option.checked}" @click.prevent="checkThisOption(option)">
                                                {{option.name}} <span class="text-xs opacity-40">(ID: {{option.id}})</span>
                                            </span>
                                        </div>
                                    </div>
                                </DisclosurePanel>
                            </Disclosure>
                        </div>
                        <div v-if="!loading && attribGroups.length === 0">
                            <EmptyState></EmptyState>
                        </div>
                        <!-- /End replace -->
                    </div>
                  </div>
                  <div class="flex flex-shrink-0 justify-end px-4 py-4">
                    <button type="button" class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2" @click.prevent="closeModal">Anuluj</button>
                    <button type="submit" class="ml-4 inline-flex justify-center rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2" @click.prevent="handleAttribValueBuffer">Dodaj atrybuty</button>
                  </div>
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
    import moment from 'moment';
    // import loadingSpinner from '@/components/loadingSpinner.vue';
    import {db} from "@/firebase/gfbconf.js";
    import { MinusSmIcon, PlusSmIcon} from '@heroicons/vue/solid';
    import { XIcon, CheckIcon } from '@heroicons/vue/outline';
    import EmptyState from '../components/EmptyState.vue';
    import { Dialog, DialogTitle, TransitionChild, TransitionRoot, Disclosure, DisclosureButton, DisclosurePanel} from '@headlessui/vue'
    export default {
        name: "AttribModal",
        components: {EmptyState,CheckIcon,
        // loadingSpinner,
        Dialog, DialogTitle,TransitionChild,TransitionRoot, XIcon, Disclosure, DisclosureButton, DisclosurePanel, MinusSmIcon, PlusSmIcon},
        data()
        {
            return {
                open: true,
                attribGroups: [],
                loading: false,
            }
        },
        created()
        {
            this.getAttribs()
        },
        methods:
        {
            uncheckAll()
            {
                for(let i=0; i< this.attribGroups.length; i++)
                {
                    for(let y=0; y< this.attribGroups[i].list.length;y++)
                    {
                        this.attribGroups[i].list[y].checked = false;
                    }
                }
            },
            checkThisOption(option)
            {
                option.checked = !option.checked;
            },
            constructAttribValueForDb(value,attrib)
            {
                return {
                    name: value.name,
                    id: value.id.toString(),
                    ref: db.collection("Attributes").doc(value.id.toString()),
                    meta: {
                        addedBy: `${this.$store.state.userData.name} ${this.$store.state.userData.surname}`,
                        userId: this.$store.state.userData.id,
                        creationDate: moment().toDate()
                    },
                    parent: {
                        name: attrib.name,
                        id: attrib.id.toString(),
                        type: attrib.type,
                        ref: db.collection("GroupsOfAttributes").doc(attrib.id)
                    }
                }
            },
            handleAttribValueBuffer()
            {
                let buffer = [];
                
                for(let i=0; i< this.attribGroups.length; i++)
                {
                    for(let y=0; y< this.attribGroups[i].list.length;y++)
                    {
                        if(this.attribGroups[i].list[y].checked)
                        {
                            const item = this.constructAttribValueForDb(this.attribGroups[i].list[y],this.attribGroups[i])
                            buffer.push(item);
                        }
                    }
                }
                if(buffer.length>0)
                {
                    this.$emit('addSelectedOptions', buffer);
                }
                this.uncheckAll();
                this.closeModal();
                
            },
            async getAttribs()
            {
                this.loading = true;
                try
                {
                    this.attribGroups = [];
                    const attribGroupsResult = await db.collection("GroupsOfAttributes").where("type", "==", "product").get();
                    attribGroupsResult.docs.forEach((doc)=>
                    {
                        let item = doc.data();
                        for(let i=0; i<item.list.length; i++)
                        {
                            item.list[i].checked = false;
                        }
                        this.attribGroups.push(item);
                    })
                    this.loading = false;
                }
                catch(error)
                {
                    console.log(error);
                    this.loading = false;
                    this.closeModal();
                }

            },
            closeModal()
            {
                this.uncheckAll();
                this.$emit("close");
            }
        }
    }
</script>