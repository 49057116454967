<template>
    <nav class="hidden md:flex mb-4 h-8" aria-label="Breadcrumb">
        <ol role="list" class="flex space-x-4 rounded-md bg-white px-6 shadow">
        <li class="flex">
            <div class="flex items-center">
            <router-link to="/hub" class="transition ease-in-out duration-300 text-gray-400 hover:text-gray-500">
                <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                <span class="sr-only">Home</span>
            </router-link>
            </div>
        </li>
        <li class="flex">
            <div class="flex items-center">
            <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
            </svg>
            <router-link to="/dashboard/products" class="transition ease-in-out duration-300 ml-4 text-xs font-medium text-gray-500 hover:text-gray-700" aria-current="page">Moduł produkty</router-link>
            </div>
        </li>
        <li class="flex">
            <div class="flex items-center">
            <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
            </svg>
            <div class="ml-4 text-xs font-medium text-gray-400 cursor-default" aria-current="page">Tworzenie produktu</div>
            </div>
        </li>
        </ol>
    </nav>
    <router-link to="/dashboard/products" class="block md:hidden transition ease-in-out duration-300 mb-2 text-sm font-medium text-blue-500 hover:text-blue-700" aria-current="page">&larr; Cofnij</router-link>

    <div>
        <h4 class="sr-only">Progres</h4>
        <p class="text-gray-500 text-xs font-medium uppercase tracking-wide">Progres dodawania produktu</p>
        <div class="mt-2" aria-hidden="true">
        <div class="bg-gray-200 rounded-full overflow-hidden">
            <div class="h-2 transition-all duration-1000 bg-gradient-to-r from-blue-400 via-indigo-400 to-blue-600 rounded-full" :style="`width: ${progress}%`" />
        </div>
        <div class="hidden sm:grid grid-cols-5 text-sm font-medium text-gray-600 mt-1">
            <div class="text-blue-500">Podstawowe informacje</div>
            <div class="text-center text-blue-500"></div>
            <div class="text-center text-blue-500">Lokalizacja</div>
            <div class="text-center text-blue-500">Atrybuty</div>
            <div class="text-right text-blue-500" >Koniec</div>
        </div>
        </div>
    </div>

    <form class="space-y-8 divide-y divide-gray-200 mt-6" v-show="step === 1">
        <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <div>
                <div>
                    <p class="text-gray-500 text-sm font-medium uppercase tracking-wide">Podstawowe informacje</p>
                </div>
                <div class="mt-6 sm:mt-2 space-y-6 sm:space-y-5">
                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Nazwa produktu<span class="text-red-500">*</span> </label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <div class="max-w-lg flex rounded-md shadow-sm">
                                <input type="text" maxlength="60" v-model="product.name" class="flex-1 block w-full py-2 px-4 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                            </div>
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="cover-photo" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Zdjęcie </label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2" >
                            <div class="group flex justify-center align-center rounded-md border-2 border-gray-300 overflow-hidden w-40 h-40 bg-white" v-show="imgPreviewSrc.length>0">
                                <img @click="triggerImgClick" :src="imgPreviewSrc" class="transition ease-in-out duration-300 object-center object-contain w-full group-hover:opacity-70 cursor-pointer">
                            </div>
                            <div v-show="imgPreviewSrc.length>0" class="mt-2 mb-2 flex items-center justify-center w-40">
                                <button type="button" @click.prevent="clearImg" class="transition ease-in-out duration-300 inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Usuń zdjęcie</button>
                            </div>
                            
                            
                            <div class="transition ease-in-out duration-300 max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md hover:opacity-70 cursor-pointer select-none" @click="triggerImgClick" v-show="imgPreviewSrc.length === 0" @drop.prevent="dropFile" @dragenter.prevent @dragover.prevent>
                                <div class="space-y-1 text-center">
                                <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                    <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                <div class="flex items-center text-sm text-gray-600 pointer-events-none">
                                    <label for="file-upload" class="transition ease-in-out duration-300 relative cursor-pointer rounded-md font-medium text-blue-500 hover:text-blue-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500">
                                    <span>Wgraj zdjęcie</span>
                                    <input id="file-upload" name="file-upload" type="file" accept="image/*" class="sr-only" ref="mainImgUploader" @change="setMainImage" />
                                    </label>
                                    <p class="pl-1">lub przeciągnij je tutaj</p>
                                </div>
                                <p class="text-xs text-gray-500">.PNG, .JPG, .GIF, .SVG do 2 mb</p>
                                <p class="text-xs text-gray-500">Preferowana wielkość: 160x160 px</p>
                                </div>
                            </div>
                            <span @click.prevent="toggleOtherSourcePhoto" class="text-xs text-blue-500 select-none hover:text-blue-700 cursor-pointer">{{showImgInputField === true ? "Cofnij wpisywanie" : "Kliknij, aby podać link z innego źródła.."}}</span>
                            <div v-if="showImgInputField" class="mt-2 w-full flex items-center">
                                <div class="mt-1 border-b border-gray-300 focus-within:border-blue-600 w-1/2">
                                    <input type="text" v-model="imgExternalInputValue" class="block w-full border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 sm:text-sm" placeholder="URL obrazka" />
                                </div>
                                <div class="ml-2" v-if="this.imgExternalInputValue.length > 0">
                                  <button type="button" @click.prevent="replaceImgWithExternalLink" class="inline-flex items-center rounded border border-transparent bg-indigo-100 px-2.5 py-1.5 text-xs font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Zapisz</button>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Kategoria</label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <div class="max-w-lg flex rounded-md shadow-sm">
                                <select @change="setCategory" v-model="setOptions.category" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md">
                                    <option value="">Brak</option>
                                    <option v-for="(category,categoryIndex) in options.category" :value="categoryIndex" :key="categoryIndex">{{category.name}} - (ID: {{category.id}})</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Producent</label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <div v-if="!product.selfProduction" class="max-w-lg flex rounded-md shadow-sm">
                                <input type="text" maxlength="40" v-model="product.manufacturer.name" :disabled="product.selfProduction" class="flex-1 block w-full py-2 px-4 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                            </div>
                            <div v-if="product.selfProduction" class="py-1.5">
                                <span class="py-2  sm:text-sm">{{product.manufacturer.name}}</span>
                            </div>
                            
                            <fieldset class="mt-2">
                                <legend class="sr-only">Produkcja własna</legend>
                                <div class="relative flex items-start">
                                    <div class="flex h-5 items-center">
                                        <input id="selfProduction" @change="changeManufacturer()" v-model="product.selfProduction" aria-describedby="comments-description" name="comments" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500 cursor-pointer" />
                                    </div>
                                    <div class="ml-2 text-sm">
                                        <label for="selfProduction" class="text-gray-700 cursor-pointer select-none">Produkcja własna</label>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Model</label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <div class="max-w-lg flex rounded-md shadow-sm">
                                <input maxlength="40" v-model="product.model" type="text" class="flex-1 block w-full py-2 px-4 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                            </div>
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Wymiary</label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <div class="sm:grid sm:grid-cols-3 max-w-lg sm:gap-4 sm:items-start">
                                <div class="mt-1 sm:mt-0">
                                    <span class="text-gray-500 text-xs pl-1">Długość</span>
                                    <div>
                                        <div class="max-w-lg relative rounded-md shadow-sm">
                                        <input type="number" @change="checkLength" name="dimension" v-model="product.dimensions.length" id="dimension" class="flex-1 block w-full py-2 pl-4 pr-12 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" placeholder="Długość" aria-describedby="length-unit" />
                                        <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                            <span class="text-gray-500 sm:text-sm" id="price-currency"> cm </span>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-1 sm:mt-0">
                                    <span class="text-gray-500 text-xs pl-1">Wysokość</span>
                                    <div>
                                        <div class="max-w-lg relative rounded-md shadow-sm">
                                        <input type="number" @change="checkHeight" name="dimension" v-model="product.dimensions.height" id="dimension" class="flex-1 block w-full py-2 pl-4 pr-12 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" placeholder="Wysokość" aria-describedby="height-unit" />
                                        <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                            <span class="text-gray-500 sm:text-sm" id="price-currency"> cm </span>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-1 sm:mt-0">
                                    <span class="text-gray-500 text-xs pl-1">Szerokość</span>
                                    <div>
                                        <div class="max-w-lg relative rounded-md shadow-sm">
                                        <input type="number" @change="checkWidth" name="dimension" v-model="product.dimensions.width" id="dimension" class="flex-1 block w-full py-2 pl-4 pr-12 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" placeholder="Szerokość" aria-describedby="width-unit" />
                                        <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                            <span class="text-gray-500 sm:text-sm" id="price-currency"> cm </span>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Waga</label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <div class="sm:grid sm:grid-cols-1 max-w-lg sm:gap-4 sm:items-start">
                                <div class="mt-1 sm:mt-0">
                                    <div>
                                        <div class="max-w-lg relative rounded-md shadow-sm">
                                        <input type="number" @change="checkWeight" name="dimension" v-model="product.weight.amount" id="dimension" class="flex-1 block w-full py-2 pl-4 pr-12 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" placeholder="Waga" aria-describedby="length-unit" />
                                        <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                            <span class="text-gray-500 sm:text-sm" id="price-currency"> kg </span>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Ilość paczek</label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <div class="sm:grid sm:grid-cols-1 max-w-lg sm:gap-4 sm:items-start">
                                <div class="mt-1 sm:mt-0">
                                    <div>
                                        <div class="max-w-lg relative rounded-md shadow-sm">
                                        <input type="number" @change="fixAmountOfPackages()" name="amountOfPackages" v-model="product.amountOfPackages" id="dimension" class="flex-1 block w-full py-2 px-4 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" placeholder="Ilość paczek" aria-describedby="amountOfPackages" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> EAN</label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <div class="max-w-lg flex rounded-md shadow-sm">
                                <input v-model="product.ean" maxlength="14" type="text" class="flex-1 block w-full py-2 px-4 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                            </div>
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> SKU</label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <div class="max-w-lg flex rounded-md shadow-sm">
                                <input v-model="product.sku" maxlength="14" type="text" class="flex-1 block w-full py-2 px-4 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                            </div>
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label for="about" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Opis produktu </label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <textarea v-model="product.description" rows="3" class="max-w-lg py-2 px-4 shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300 rounded-md" />
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Pole dodatkowe 1</label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <div class="max-w-lg flex rounded-md shadow-sm">
                                <input v-model="product.extraField1" type="text" class="flex-1 block w-full py-2 px-4 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                            </div>
                            <p class="mt-2 text-xs text-gray-500" >Pole dodatkowe, bez przeznaczenia.</p>
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Pole dodatkowe 2</label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <div class="max-w-lg flex rounded-md shadow-sm">
                                <input type="text" v-model="product.extraField2" class="flex-1 block w-full py-2 px-4 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                            </div>
                            <p class="mt-2 text-xs text-gray-500" >Pole dodatkowe, bez przeznaczenia.</p>
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Pole dodatkowe 3</label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <div class="max-w-lg flex rounded-md shadow-sm">
                                <input type="text" v-model="product.extraField3" class="flex-1 block w-full py-2 px-4 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                            </div>
                            <p class="mt-2 text-xs text-gray-500">Pole dodatkowe, bez przeznaczenia.</p>
                        </div>
                    </div>



                </div>
            </div>            


        </div>

        <div class="pt-5">
        <div class="flex flex-col sm:flex-row justify-end">
            <button type="submit" @click.prevent="changeStep(2)" :class="{'transition ease-in-out duration-300 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 ': true, 'bg-blue-300': !validation1, 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500': validation1}">Następny krok</button>
        </div>
        </div>
    </form>

    



    <form class="space-y-8 divide-y divide-gray-200 mt-6" v-show="step === 2">
        <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <div>
                <div>
                    <p class="text-gray-500 text-sm font-medium uppercase tracking-wide">Informacje o lokalizacji</p>
                </div>
                <div class="mt-6 sm:mt-2 space-y-6 sm:space-y-5">

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Położenie</label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <div class="max-w-lg flex rounded-md shadow-sm">
                                <input maxlength="40" v-model="product.storage.location" type="text" class="flex-1 block w-full py-2 px-4 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                            </div>
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Dodatkowe informacje</label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <div class="max-w-lg flex rounded-md shadow-sm">
                                <input maxlength="65" v-model="product.storage.additionalInfo" type="text" class="flex-1 block w-full py-2 px-4 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                            </div>
                        </div>
                    </div>

                </div>

                <div class="mt-12">
                    <p class="text-gray-500 text-sm font-medium uppercase tracking-wide">Zewnętrzne źródła</p>
                </div>

                <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Link</label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <div class="max-w-lg flex rounded-md shadow-sm">
                                <input v-model="product.link.href" type="text" class="flex-1 block w-full py-2 px-4 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                            </div>
                            <p class="mt-2 text-xs text-gray-500">Uzupełnienie tego pola umożliwi szybki dostęp do tego produktu. </p>
                            <p class="mt-2 text-xs text-gray-500 italic">Przykładowo: </p>
                            <p class="text-xs text-gray-500 italic">https://jan-art.sklep.pl/produkt?id=12345</p>
                            
                        </div>
                    </div>



                </div>


            </div>            
        </div>

        <div class="pt-5">
        <div class="flex flex-col sm:flex-row justify-end">
            <button type="button" @click.prevent="changeStep(1)" class="transition ease-in-out duration-300 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Poprzedni krok</button>
            <button type="submit" @click.prevent="changeStep(3)" :class="{'transition ease-in-out duration-300 mt-2 sm:mt-0 sm:ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 ': true, 'bg-blue-300': !validation1, 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500': validation1}">Następny krok</button>
        </div>
        </div>
    </form>

    <form class="space-y-8 divide-y divide-gray-200 mt-4" v-show="step === 3">
        <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            <div class="mt-5">
                <div :class="['flex justify-between items-center', product.attribs.list.length>0 ? 'border-b border-gray-200 pb-4' : '']">
                    <p class="text-gray-500 text-sm font-medium uppercase tracking-wide">Atrybuty</p>
                    <button @click.prevent="showModal = true" type="button" class="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                        <PlusSmIconOutline class="h-5 w-5" aria-hidden="true" />
                    </button>
                </div>
                <div class="mt-6">
                    <ul role="list" class="-my-5 divide-y divide-gray-200" v-if="product.attribs.list.length>0">
                        <li v-for="(attrib,atrbIndx) in product.attribs.list" :key="attrib.id" :class="[atrbIndx < product.attribs.list.length -1 ? 'py-4' : 'pt-4 pb-2']">
                            <div class="flex items-center space-x-4">
                                <div class="flex-shrink-0">
                                    <!-- <img class="h-10 w-10 rounded-full" :src="person.imageUrl" alt="" /> -->
                                    <div class="h-10 w-10 rounded-full text-white bg-green-400 flex justify-center items-center text-sm"> {{attrib.parent.name.substring(0,3).toUpperCase()}}</div>
                                </div>
                                <div class="flex-1 min-w-0">
                                    <p class="text-sm font-medium text-gray-900 truncate">
                                        <router-link :to="`/dashboard/products/attributes/modify?attrib=${attrib.parent.id}`" target="_blank" class="text-xs opacity-50 hover:text-blue-500">({{attrib.parent.name}})</router-link> {{ attrib.name }} 
                                    </p>
                                    <p class="text-sm text-gray-500 truncate">
                                        ID: {{ attrib.id }}
                                    </p>
                                </div>
                                <div>
                                    <button class="transition ease-in-out duration-300 inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-red-50 hover:text-red-500 hover:border-red-200" @click.prevent="product.attribs.list.splice(atrbIndx,1)"> Usuń </button>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <EmptyState v-if="product.attribs.list.length === 0"></EmptyState>
                </div>


            </div>
       
        </div>

        <div class="pt-5">
            <div class="flex flex-col sm:flex-row justify-end">
                <button type="button" @click.prevent="changeStep(2)" class="transition ease-in-out duration-300 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Poprzedni krok</button>
                <button type="submit" @click.prevent="changeStep(4)" :class="{'transition ease-in-out duration-300 mt-2 sm:mt-0 sm:ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 ': true, 'bg-blue-300': !validation1, 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500': validation1}">Stwórz produkt</button>
                <button type="submit" @click.prevent="changeStep(5)" :class="{'transition ease-in-out duration-300 mt-2 sm:mt-0 sm:ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 ': true, 'bg-blue-300': !validation1, 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500': validation1}">Stwórz produkt i dodaj warianty</button>
            </div>
        </div>
    </form>
    <AttribListModal :show="showModal" @close="closeModal" @addSelectedOptions="pushToAttribList"></AttribListModal>

</template>

<script>
    import moment from 'moment';
    import axios from 'axios';
    import { PlusSmIcon as PlusSmIconOutline } from '@heroicons/vue/outline'
    import { HomeIcon } from '@heroicons/vue/solid'
    import {db,storage} from "@/firebase/gfbconf.js";
    import AttribListModal from '@/components/AttribListModal.vue';
    import EmptyState from '../components/EmptyState.vue';
    export default {
        name: "ProductAdd",
        data()
        {
            return {
                imgExternalInputValue: "",
                showImgInputField: false,
                fileLimitSize: 2097152,
                showModal: false,
                setOptions: {
                    category: "",
                },
                imgPreviewSrc: "",
                progress: 0,
                step: 1,
                options: {
                    category: [],
                },
                product: {
                    name: "",
                    model: "",
                    active: true,
                    meta: {
                        createdBy: null,
                        createdDate: null,
                        lastModificationDate: null,
                        listOfChanges: []
                    },
                    ean: "",
                    sku: "",
                    image: {
                        url: "",
                        ref: null
                    },
                    amountOfPackages: 1,
                    manufacturer: {
                        name: '',
                    },
                    selfProduction: false,
                    category: {
                        name: "",
                        ref: null,
                        id: "",
                    },
                    elasticSearch: {
                        docId: "",
                        index: "",
                        creationDate: ""
                    },
                    extraField1: "",
                    extraField2: "",
                    extraField3: "",
                    description: "",
                    dimensions: {
                        unit: "cm",
                        height: 0,
                        width: 0,
                        length: 0
                    },
                    weight: {
                        unit: "kg",
                        amount: 0
                    },
                    storage: {
                        location: "",
                        additionalInfo: ""
                    },
                    link: {
                        href: ""
                    },
                    attribs: {
                        list: []
                    },
                    variants: {}
                }
            }
        },
        components: {
            PlusSmIconOutline,
            AttribListModal,
            EmptyState,
            HomeIcon
        },
        created()
        {
            if(!this.$store.state.userData.permissions.admin && !this.$store.state.userData.permissions.products)
            {
                this.$router.push("/hub")
            }
            this.allToFixed2();
            this.getAdditionalOptions();
        },
        methods:
        {
            replaceImgWithExternalLink()
            {
                if(this.imgExternalInputValue.length === 0) return;
                this.clearImg();
                this.product.image.ref = null;
                this.product.image.url = this.imgExternalInputValue;
                this.imgPreviewSrc = this.imgExternalInputValue;
                this.imgExternalInputValue = "";
                this.showImgInputField = false;
            },
            toggleOtherSourcePhoto()
            {
                this.clearImg();
                this.product.image.ref = null;
                this.product.image.url = "";
                this.showImgInputField = !this.showImgInputField;
            },
            fixAmountOfPackages(){
                this.product.amountOfPackages = Math.ceil(this.product.amountOfPackages)
                if(this.product.amountOfPackages < 1){
                    this.product.amountOfPackages = 1
                }
                if(this.product.amountOfPackages > 100){
                    this.product.amountOfPackages = 100
                }
                this.product.amountOfPackages = parseInt(this.product.amountOfPackages)
            },
            changeManufacturer(){
                if(this.product.selfProduction){
                    this.product.manufacturer.name = "Jan-Art Sp. z o.o."
                }else{
                    this.product.manufacturer.name = ""
                }
            },
            pushToAttribList(buffer)
            {
                for(let i=0; i<buffer.length; i++)
                {
                    this.product.attribs.list.push(buffer[i]);
                }
            },
            closeModal()
            {
                this.showModal = false;
            },
            // setManufacturer()
            // {
            //     let newValue = this.options.manufacturer[this.setOptions.manufacturer];
            //     if(newValue === "")
            //     {
            //         this.product.manufacturer.name = "";
            //         this.product.manufacturer.ref = null;
            //         this.product.manufacturer.id = "";
            //     }
            //     else
            //     {
            //         this.product.manufacturer.name = newValue.name;
            //         this.product.manufacturer.id = newValue.id.toString();
            //         this.product.manufacturer.ref = this.$store.state.userData.system.ref.collection("Manufacturers").doc(newValue.id.toString());
            //     }
            // },
            setCategory()
            {
                let newValue = this.options.category[this.setOptions.category];
                if(newValue === "")
                {
                    this.product.category.name = "";
                    this.product.category.ref = null;
                    this.product.category.id = "";
                }
                else
                {
                    this.product.category.name = newValue.name;
                    this.product.category.id = newValue.id.toString();
                    this.product.category.ref = db.collection("Categories").doc(newValue.id.toString());
                }
            },
            async getAdditionalOptions()
            {
                const categoryDocsResult = await db.collection("Categories").get();
                for(let i=0; i<categoryDocsResult.docs.length; i++)
                {
                    this.options.category.push(categoryDocsResult.docs[i].data());
                }
            },
            dropFile(e)
            {
                if(e.dataTransfer.files.length>0)
                {
                    if(e.dataTransfer.files[0].type.split('/')[0] === 'image')
                    {
                        if(e.dataTransfer.files[0].size <= this.fileLimitSize)
                        {
                            const fileReader = new FileReader();
                            fileReader.readAsDataURL(e.dataTransfer.files[0]);
                            fileReader.addEventListener("load", () =>
                            {
                                this.imgPreviewSrc = fileReader.result;
                            })
                        }
                        else
                        {
                            this.$store.commit('setNotification',{
                                show: true,
                                head: "Zdjęcie waży powyżej 2MB",
                                subheader: `Plik jest zbyt ciężki.`,
                                success: false
                            }); 
                        }
                    }
                    else
                    {
                        this.$store.commit('setNotification',{
                            show: true,
                            head: "Plik nie jest zdjęciem.",
                            subheader: `Plik powinien być zdjęciem.`,
                            success: false
                        });   
                    }
                }
                
            },
            triggerImgClick()
            {
                this.$refs.mainImgUploader.click();
            },
            clearImg()
            {
                this.imgPreviewSrc = '';
                this.$refs.mainImgUploader.value = "";
            },
            allToFixed2()
            {
                this.product.dimensions.height = 0;
                this.product.dimensions.width = 0;
                this.product.dimensions.length = 0;
                this.product.weight.amount = 0;
                this.product.dimensions.height = this.product.dimensions.height.toFixed(2);
                this.product.dimensions.width = this.product.dimensions.width.toFixed(2);
                this.product.dimensions.length = this.product.dimensions.length.toFixed(2);
                this.product.weight.amount = this.product.weight.amount.toFixed(2);
            },
            checkHeight()
            {
                if(this.product.dimensions.height.length === 0)
                {
                    this.product.dimensions.height = 0;
                }
                if(this.product.dimensions.height < 0)
                {
                    this.product.dimensions.height = 0;
                }
                else
                {
                    this.product.dimensions.height = parseFloat(this.product.dimensions.height);
                }
                this.product.dimensions.height = this.product.dimensions.height.toFixed(2);
            },
            checkWidth()
            {
                if(this.product.dimensions.width.length === 0)
                {
                    this.product.dimensions.width = 0;
                }
                if(this.product.dimensions.width < 0)
                {
                    this.product.dimensions.width = 0;
                }
                else
                {
                    this.product.dimensions.width = parseFloat(this.product.dimensions.width);
                }
                this.product.dimensions.width = this.product.dimensions.width.toFixed(2);
            },
            checkLength()
            {
                if(this.product.dimensions.length.length === 0)
                {
                    this.product.dimensions.length = 0;
                }
                if(this.product.dimensions.length < 0)
                {
                    this.product.dimensions.length = 0;
                }
                else
                {
                    this.product.dimensions.length = parseFloat(this.product.dimensions.length);
                }
                this.product.dimensions.length = this.product.dimensions.length.toFixed(2);
            },
            checkWeight()
            {
                if(this.product.weight.amount.length === 0)
                {
                    this.product.weight.amount = 0;
                }
                if(this.product.weight.amount < 0)
                {
                    this.product.weight.amount = 0;
                }
                else
                {
                    this.product.weight.amount = parseFloat(this.product.weight.amount);
                }
                this.product.weight.amount = this.product.weight.amount.toFixed(2);
            },
            setMainImage()
            {
                if(this.$refs.mainImgUploader.files.length>0)
                {
                    if(this.$refs.mainImgUploader.files[0].size <= this.fileLimitSize)
                    {
                        const fileReader = new FileReader();
                        fileReader.readAsDataURL(this.$refs.mainImgUploader.files[0]);
                        fileReader.addEventListener("load", () =>
                        {
                            this.imgPreviewSrc = fileReader.result;
                        })
                    }
                    else
                    {
                        this.$store.commit('setNotification',{
                            show: true,
                            head: "Zdjęcie waży powyżej 2MB",
                            subheader: `Plik jest zbyt ciężkie.`,
                            success: false
                        });                 
                    }
                }
            },
            prepareElasticDocument(product)
            {
                let document = {
                    names: [product.name],
                    models: product.model.length > 0 ? [product.model] : [],
                    skus: product.sku.length > 0 ? [product.sku] : [],
                    eans: product.ean.length > 0 ? [product.ean] : [],
                    id: product.id,
                    categories: product.category.name.length > 0 ? [product.category.name] : [],
                    manufacturers: product.manufacturer.name.length > 0 ? [product.manufacturer.name] : [],
                    variantsIds: [],
                    baselinkerIds: [product.baselinkerId.toString()],
                }

                return document;
            },
            filenameGenerator(length)
            {
                let result           = `prod_`;
                let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                let charactersLength = characters.length;
                for ( let i = 0; i < length; i++ ) {
                    result += characters.charAt(Math.floor(Math.random() * 
                    charactersLength));
                }
                return result;
            },
            async addProductToDb(type)
            {
                this.$store.commit('setGlobalLoader',{
                    show: true,
                    head: "Tworzenie nowego produktu..",
                    subheader: `Nie zamykaj tego okna.`,
                });
                try
                {
                    // Upload an image
                    if(this.$refs.mainImgUploader.files.length>0)
                    {
                        const storageRef = storage.ref();
                        const filename = this.filenameGenerator(10);
                        const path = `products/${filename}`;
                        const imageRef = storageRef.child(path);
                        await imageRef.put(this.$refs.mainImgUploader.files[0]);
                        const fileUrl = await imageRef.getDownloadURL();
                        this.product.image.url = fileUrl;
                        this.product.image.ref = path;
                    }
                    this.product.meta.createdDate = moment().toDate();
                    this.product.meta.lastModificationDate = moment().toDate();
                    this.product.meta.createdBy = `${this.$store.state.userData.name} ${this.$store.state.userData.surname}`;
                    this.product.meta.userId = this.$store.state.userData.id;
                    // Convert to float to be sure to have this in good format in db.
                    this.product.dimensions.height = parseFloat(this.product.dimensions.height);
                    this.product.dimensions.width = parseFloat(this.product.dimensions.width);
                    this.product.dimensions.length = parseFloat(this.product.dimensions.length);
                    this.product.weight.amount = parseFloat(this.product.weight.amount);

                    const itemRef = db.collection("Products");
                    const resultOfAdding = await itemRef.add(
                        this.product
                    )
                    // Update current doc id  
                    itemRef.doc(resultOfAdding.id).update({id: resultOfAdding.id}).catch((error)=>
                    {
                        console.log(error);
                    });

                    this.product.id = resultOfAdding.id

                    let baselinkerRes = await axios.post(`${this.$store.state.apiLink}/baselinker/product/add`, {
                        whoAmI: this.$store.state.userData.id,
                        product: this.product
                    })

                    if(baselinkerRes.data.success)
                    {
                        this.product.baselinkerId = baselinkerRes.data.ids.main;
                    }
                    else
                    {
                        this.product.baselinkerId = '';
                    }

                    let document = this.prepareElasticDocument(this.product);

                    let elasticRes = await axios.post(`${this.$store.state.apiLink}/elastic/add`, {
                        index: "jan-art-products",
                        collection: "Products",
                        firestoreId: this.product.id,
                        document: document
                    })
                    
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Produkt został stworzony!",
                        subheader: `Twój produkt jest gotowy.`,
                        success: true
                    });  
                    if(type === 'product'){
                    this.$router.push(`/dashboard/products/list/viewProduct?id=${this.product.id}`)
                    }
                    if(type === 'variants'){
                    this.$router.push(`/dashboard/products/list/editProduct?id=${resultOfAdding.id}&step=3`)
                    }
                }
                catch(error)
                {
                    console.log(error);
                }
                

            },
            changeStep(val)
            {
                switch(val)
                {
                    case 1: 
                        this.progress = 0;
                        this.step = 1;
                        break;
                    case 2:
                        if(this.validation1 === true)
                        {
                            this.step = 2;
                            this.progress = 50;
                        }
                        break;
                    case 3:
                        this.step = 3;
                        this.progress = 70;
                        break;
                    case 4:
                        this.step = 4;
                        this.progress = 100;
                        this.addProductToDb('product');
                        break;
                    case 5:
                        this.step = 4;
                        this.progress = 100;
                        this.addProductToDb('variants');
                }
            }
        },
        computed:
        {
            validation1()
            {
                if(this.product.name.length>0)
                {
                    return true;
                }
                else
                {
                    return false;
                }
            }
        }
    }
</script>
<style lang="scss" scoped>

</style>